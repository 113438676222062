import { cacheExchange, createClient, fetchExchange } from 'urql';
import { authExchange, AuthConfig } from '@urql/exchange-auth';
import { reissueToken } from '@realestate.com.au/user';

import { COMPANION_BASE_URL } from '../environment';

export const client = createClient({
  url: `${COMPANION_BASE_URL}/graphql`,
  exchanges: [
    cacheExchange,
    authExchange(async () => {
      const authConfig: AuthConfig = {
        addAuthToOperation: (operation) => operation,
        didAuthError: (error) => {
          return error.graphQLErrors.some(
            (e) => e.extensions?.code === 'UNAUTHENTICATED'
          );
        },
        refreshAuth: async () => {
          await reissueToken();
        },
      };
      return authConfig;
    }),
    fetchExchange,
  ],
  fetchOptions: {
    credentials: 'include',
  },
});
