import { DataGetter } from '@argonaut/analytics';

import { EventData } from '../types';

const EVENT_NAME_FINX_FORM_INTERACTION = 'finx_form_interaction';
const SCHEMA_FINX_FORM_INTERACTION =
  'iglu:au.com.realestate/finx_form_interaction/jsonschema/3-0-2';

export interface FormInteractionEventData extends EventData {
  schema: string;
  data: {
    event_name: string;
    source: string;
    user_action: string;
  };
}

export const getFinXFormInteractionEventDataGetter =
  (source: string, user_action: string): DataGetter =>
  (): FormInteractionEventData => ({
    schema: SCHEMA_FINX_FORM_INTERACTION,
    data: {
      event_name: EVENT_NAME_FINX_FORM_INTERACTION,
      source,
      user_action,
    },
  });
