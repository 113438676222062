/* eslint-disable camelcase */

export const contactFormSubmittedEvent = {
  schema: 'iglu:au.com.realestate/contact_form_submitted/jsonschema/2-0-11',
  data: {
    event_name: 'contact_form_submitted',
    form_name: 'quick_lead',
    fulfilment_channel: 'broker',
  },
};
/* eslint-disable camelcase */
