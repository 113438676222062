import styled from 'styled-components';
import { CurrencyInput, paletteHelper, TextInput } from '@construct-kit/core';

import { FormattedNumericInput } from '.';

// These styles are a direct copy of the CK component styles to ensure that they are not overriden by Content Hub styles
// For clarity, please do not alter styles directly here, but extend this component so we can clearly see
// CK vs our custom styling.
export const UnsetCurrencyInput = styled(CurrencyInput).attrs({
  dangerouslySetClassNames: {
    input: 'unsetInput',
    iconLeftWrapper: 'iconWrapper',
    label: 'unsetLabel',
  },
})`
  .unsetLabel {
    color: var(--ck-textPrimary);
  }

  .iconWrapper {
    height: 2rem;
  }

  .unsetInput {
    border: none;
    font-size: 1rem;
    line-height: 1.5rem;
    font-family: Pangea, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-variation-settings:
      'XTDR' 50,
      'APRT' 100,
      'SPAC' 40;
    font-weight: 400;
    border: none;
    color: var(--ck-textPrimary);
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 2rem;
    width: 100%;
    min-width: 0px;
    outline: none;
    box-shadow: none;
    padding: 0px;
    margin-bottom: 0.5rem;
    background-color: var(--ck-backgroundPrimaryBase);
    cursor: inherit;
    scroll-snap-align: start;
    scroll-margin-left: 3rem;
  }
`;
// These styles are a direct copy of the CK component styles to ensure that they are not overriden by Content Hub styles
// For clarity, please do not alter styles directly here, but extend this component so we can clearly see
// CK vs our custom styling.
export const UnsetInput = styled(TextInput).attrs({
  dangerouslySetClassNames: {
    input: 'unsetInput',
  },
})`
  .unsetInput {
    border: none;

    font-size: 1rem;
    line-height: 1.5rem;
    font-family: Pangea, 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-variation-settings:
      'XTDR' 50,
      'APRT' 100,
      'SPAC' 40;
    font-weight: 400;
    border: none;
    color: var(--ck-textPrimary);
    -webkit-box-flex: 1;
    flex-grow: 1;
    height: 2rem;
    width: 100%;
    min-width: 0px;
    outline: none;
    box-shadow: none;
    padding: 0px;
    margin-bottom: 0.5rem;
    background-color: var(--ck-backgroundPrimaryBase);
    cursor: inherit;
    scroll-snap-align: start;
    scroll-margin-left: 3rem;
  }
`;

export const StyledCurrencyInput = styled(UnsetCurrencyInput).attrs({
  dangerouslySetClassNames: {
    input: 'unsetInput',
    iconLeftWrapper: 'iconWrapper',
    iconLeft: 'iconLeft',
  },
})`
  width: 120px;
  .unsetInput {
    margin-bottom: 0.25rem;
    color: ${(props) =>
      paletteHelper(props.disabled ? 'textDisabled' : 'textPrimary')};
    background-color: ${(props) =>
      paletteHelper(
        props.disabled ? 'backgroundDisabled' : 'backgroundPrimaryBase'
      )};
  }
  .iconLeft {
    color: ${(props) =>
      paletteHelper(props.disabled ? 'textDisabled' : 'textPrimary')};
  }
`;

export const StyledUnsetInputInput = styled(UnsetInput).attrs({
  dangerouslySetClassNames: {
    input: 'unsetInput',
  },
})`
  .unsetInput {
    margin-bottom: 0.25rem;
    color: ${(props) =>
      paletteHelper(props.disabled ? 'textDisabled' : 'textPrimary')};
    background-color: ${(props) =>
      paletteHelper(
        props.disabled ? 'backgroundDisabled' : 'backgroundPrimaryBase'
      )};
  }
`;

export const PercentInput = styled(FormattedNumericInput)`
  width: 80px;
`;

export const LoanTermInput = styled(FormattedNumericInput)`
  width: 90px;
`;
