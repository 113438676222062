import { DataGetter } from '@argonaut/analytics';

import { EventData } from '../types';

const EVENT_NAME_CONTACT_FORM_SUBMITTED = 'contact_form_submitted';
const SCHEMA_CONTACT_FORM_SUBMITTED =
  'iglu:au.com.realestate/contact_form_submitted/jsonschema/2-0-14';

export interface FormSubmittedEventData extends EventData {
  schema: string;
  data: {
    event_name: string;
    form_name: string;
  };
}

export const getContactFormSubmittedEventDataGetter =
  (formName: string): DataGetter =>
  (): FormSubmittedEventData => ({
    schema: SCHEMA_CONTACT_FORM_SUBMITTED,
    data: {
      event_name: EVENT_NAME_CONTACT_FORM_SUBMITTED,
      form_name: formName,
    },
  });
