import { CommonQuickLeadsModal } from '@rea-group/fs-quick-lead-components';
import { TableItem } from '@rea-group/fs-quick-lead-components/lib/types/components/forms/QuickLeadsWithDetails/DetailsTable';
import { Address } from '@rea-group/fs-quick-lead-components/lib/types/components/forms/QuickLeadsWithDetails/types';
import { trackEvent as trackEventFun } from '@argonaut/analytics';

import { leadSourceForNextStep } from '../../constants';
import { submitEnquiryForm } from '../../utils/handleRequest';
import { getContactFormSubmittedEventDataGetter } from '../../tracking/eventData/formSubmitted';
import { TrackEventProps } from '../../tracking/types';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
  detailsWithLabels: TableItem[];
  address?: Address;
} & TrackEventProps;

export const formNameForNextStep: string = 'nextstep_callback';

export const NextStepModal = ({
  isOpen,
  closeModal,
  detailsWithLabels,
  address,
  trackEvent = trackEventFun,
}: Props) => {
  return (
    <CommonQuickLeadsModal
      isModalOpen={isOpen}
      onClose={closeModal}
      eventSource={leadSourceForNextStep}
      trackEvent={() => {
        trackEvent(getContactFormSubmittedEventDataGetter(formNameForNextStep));
      }}
      uiTextProps={{
        dialogHeaderText: 'Let’s connect you',
        contentHeaderText:
          'Your details will be sent to a Mortgage Choice broker who can provide insights and help you make the best choice for you.',
        buttonText: 'Send',
      }}
      detailsWithLabels={detailsWithLabels}
      address={address}
      fireRequest={submitEnquiryForm}
    />
  );
};
