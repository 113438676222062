import {
  Button,
  Dialog,
  paletteHelper,
  spacingHelper,
  typographyHelper,
  mediaQueryHelper,
} from '@construct-kit/core';
import styled from 'styled-components';

export const CalcAssumptionsDialog = styled(Dialog)`
  color: ${paletteHelper('textPrimary')};
  .calcAssumptionsHeader {
    padding: ${spacingHelper('medium')};
  }
`;

export const StyledButton = styled(Button).attrs({
  variant: 'link-secondary',
})`
  ${typographyHelper('body02')}
  ${mediaQueryHelper('tablet')} {
    align-self: flex-end;
  }
`;
