import {
  LoanCalculatorCalculatorResultsArgs,
  State,
} from '../@types/__generated__/graphql';

export const getQueryParam = (name: string) => {
  if (typeof window !== 'undefined') {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(name);
  }
  return null;
};

export const getDefaultVariablesFromUrl = () => {
  const mockedStatus = getQueryParam('mockedStatus');
  const listingId = getQueryParam('listingId') || '144094084';
  const listedPriceDescription = getQueryParam('listedPriceDescription');
  const indicativePriceDescription = getQueryParam(
    'indicativePriceDescription'
  );
  const state: State = (getQueryParam('state') as State) || 'VIC';
  const suburb = getQueryParam('suburb');
  const postcode = getQueryParam('postcode');
  const bedroomsString: string | null = getQueryParam('bedrooms');
  const bedrooms = bedroomsString ? parseInt(bedroomsString) : null;
  const propertyTypeDisplay = getQueryParam('propertyTypeDisplay');

  const loanCalculatorVariables: LoanCalculatorCalculatorResultsArgs = {
    mockedStatus,
    listingDetails: {
      listingId,
      listedPriceDescription,
      indicativePriceDescription,
      state,
      suburb,
      postcode,
      bedrooms,
      propertyTypeDisplay,
    },
    financialDetails: null,
  };

  return loanCalculatorVariables;
};

export const getScreenStatusFromUrl = (): {
  loading: boolean;
  error: boolean;
} => {
  const loading = getQueryParam('loading');
  const error = getQueryParam('error');

  return { loading: !!loading, error: !!error };
};
