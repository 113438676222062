import React, { useState } from 'react';
import {
  Button,
  Checkbox,
  FigCaption,
  HomeMd,
  Inline,
  Modal,
  Stack,
} from '@construct-kit/core';
import { extractDigits, phoneNormalizer } from '@rea-group/fs-web-utilities';

import { SubmitFormData, SubmitStatus } from '../QuickLeads/types';
import DetailsTable from '../QuickLeadsWithDetails/DetailsTable';
import { submitForm, handleResponse } from '../../../utilities/handleRequest';
import { contactFormSubmittedEvent } from '../../../tracking/contactFormSubmittedEvent';
import { logError } from '../../../utilities/errorHandler';
import { COMMON_QUICK_LEADS_COMPONENT_NAME } from '../../../constants/componentName';
import { PRIVACY_POLICY } from '../../../constants/urls';
import { isNameValid, isPhoneNumberValid } from '../../../utilities/validators';
import CustomTextInput from '../QuickLeads/TextInput';
import ErrorState from '../StandardEnquiryForm/ErrorState';
import {
  Container,
  DialogHeader,
  Content,
  SencondaryText,
  ProfilePhotoImage,
  LogoImg,
  StrongText,
  PrimaryText,
  StyledLink,
} from './styles';
import TylaImage from '../../../resources/Tyla.png';
import MCLogo from '../../../resources/mortgagechoice-logo.svg';
import { CommonQuickLeadsProps } from './types';

export const CommonQuickLeadsModal = ({
  isModalOpen,
  onClose,
  detailsWithLabels,
  address,
  trackEvent,
  fireRequest = submitForm,
  eventSource,
  uiTextProps,
}: CommonQuickLeadsProps) => {
  const hasDetails = !!detailsWithLabels?.length;

  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.UNSENT);
  const [includeFinancialData, setIncludeFinancialData] = useState(hasDetails);
  const [waLead, setWaLead] = useState(false);
  const [showError, setShowError] = useState(false);

  const handlePhoneNumber = (inputPhoneNumber: string) => {
    const normalizedPhoneNumber = phoneNormalizer(inputPhoneNumber);
    setPhoneNumber(normalizedPhoneNumber);
  };

  const onSubmit = () => {
    if (!isNameValid(name) || !isPhoneNumberValid(phoneNumber)) {
      setShowError(true);
      return;
    }

    setSubmitStatus(SubmitStatus.SENDING);

    const requestBody: SubmitFormData = {
      enquiryType: 'Quick',
      stream: 'BROKER',
      phone: phoneNumber && extractDigits(phoneNumber),
      fullName: name,
      includeFinancialData,
      ...(waLead && { state: 'WA' }),
    };

    fireRequest(requestBody, eventSource)
      .then(handleResponse)
      .then(() => {
        trackEvent(contactFormSubmittedEvent);
        setSubmitStatus(SubmitStatus.SUCCESS);
        setName('');
        setPhoneNumber('');
        onClose();
      })
      .catch((error) => {
        setSubmitStatus(SubmitStatus.ERROR);
        logError(`[${COMMON_QUICK_LEADS_COMPONENT_NAME}]: ${error}`, {
          component: COMMON_QUICK_LEADS_COMPONENT_NAME,
        });
      });
  };

  const handleClose = () => {
    setShowError(false);
    onClose();
  };

  return (
    <Modal
      opened={isModalOpen}
      getAppElement={() => 'body'}
      onRequestClose={handleClose}
    >
      <Container
        onClose={handleClose}
        dangerouslySetClassNames={{
          closeButton: 'modalClose',
        }}
        autoFullScreen
        maxWidth="395px"
        header={<DialogHeader>{uiTextProps.dialogHeaderText}</DialogHeader>}
        footer={
          <Button fullWidth onClick={onSubmit}>
            {uiTextProps.buttonText}
          </Button>
        }
      >
        <Content gap="large">
          {submitStatus === SubmitStatus.ERROR && <ErrorState />}
          <PrimaryText>{uiTextProps.contentHeaderText}</PrimaryText>
          <Stack gap="medium">
            <CustomTextInput
              id="name"
              label="Name"
              placeholder="Name"
              sizeVariant="large"
              value={name}
              maxLength={100}
              onChange={(name: string) => setName(name)}
              onBlur={() => setName(name.trim())}
              isValid={isNameValid(name)}
              showError={showError}
              errorMessage="Enter a valid name"
              disabled={submitStatus === SubmitStatus.SENDING}
            />
            <CustomTextInput
              id="phone"
              type="tel"
              label="Phone number"
              placeholder="Phone number"
              sizeVariant="large"
              value={phoneNumber}
              onChange={handlePhoneNumber}
              isValid={isPhoneNumberValid(phoneNumber)}
              showError={showError}
              errorMessage="Enter a valid phone number"
              disabled={submitStatus === SubmitStatus.SENDING}
            />
          </Stack>
          <Checkbox
            label={
              <SencondaryText>
                I&apos;m in Western Australia. Check the local time before you
                call.
              </SencondaryText>
            }
            onChange={() => {
              setWaLead(!waLead);
            }}
            checked={waLead}
          />
          {hasDetails && (
            <Stack gap="small">
              <Checkbox
                label={<PrimaryText>Include my details</PrimaryText>}
                checked={includeFinancialData}
                onChange={() => setIncludeFinancialData(!includeFinancialData)}
              />
              {includeFinancialData && (
                <Stack gap="0">
                  {address && (
                    <Inline grow={false} gap={'extraSmall'}>
                      <HomeMd />
                      <StrongText>
                        {`${address.shortAddress}, ${address.suburb}`}
                      </StrongText>
                    </Inline>
                  )}
                  <DetailsTable items={detailsWithLabels} />
                </Stack>
              )}
            </Stack>
          )}
          <StyledLink target="_blank" href={PRIVACY_POLICY} variant="secondary">
            <FigCaption>Privacy collection statement</FigCaption>
          </StyledLink>
          <Inline grow={false} gap="extraSmall">
            <ProfilePhotoImage src={TylaImage} alt="Tyla photo" />
            <Stack gap="0">
              <StrongText>Tyla and team</StrongText>
              <SencondaryText variant="body02">
                Home Loans Concierge, Sydney
              </SencondaryText>
              <div>
                <LogoImg src={MCLogo} alt="MC logo" />
              </div>
            </Stack>
          </Inline>
        </Content>
      </Container>
    </Modal>
  );
};

export default CommonQuickLeadsModal;
