import styled from 'styled-components';
import {
  spacingHelper,
  paletteHelper,
  typographyHelper,
  Stack,
  Text,
  Dialog,
  Small,
  P,
  Link,
} from '@construct-kit/core';

export const DialogHeader = styled(Text).attrs({
  variant: 'title02',
})`
  width: 321px;
  color: ${paletteHelper('textPrimary')};
`;

export const Content = styled(Stack)`
  padding: ${spacingHelper('large')};
  color: ${paletteHelper('textPrimary')};
`;

export const PrimaryText = styled(P)`
  color: ${paletteHelper('textPrimary')};
`;

export const SencondaryText = styled(Text)`
  color: ${paletteHelper('textSecondary')};
`;

export const ProfilePhotoImage = styled.img`
  width: 48px;
  height: 48px;
  background-color: ${paletteHelper('backgroundSecondary')};
  border-radius: 50%;
`;

export const LogoImg = styled.img`
  height: 12px;
`;

export const Container = styled(Dialog)`
  .modalClose {
    border: none;
  }
`;

export const StrongText = styled(Small)`
  ${typographyHelper('strong')};
  color: ${paletteHelper('textPrimary')};
`;

export const StyledLink = styled(Link)`
  color: ${paletteHelper('linkSecondary')};
  &:visited {
    color: ${paletteHelper('linkSecondary')};
  }
`;
