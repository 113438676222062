import {
  compareMonetaryValues,
  ComparisonResult,
} from '../../utils/compareMonetaryValues';
import { AnimationTypeForLegendElement } from './types';

export const getAnimationTypeFromMonetaryValue = (
  previousValue: string,
  currentValue: string
): AnimationTypeForLegendElement => {
  const comparisonResult = compareMonetaryValues(previousValue, currentValue);
  switch (comparisonResult) {
    case ComparisonResult.LESS:
      return AnimationTypeForLegendElement.MOVE_IN_FROM_BOTTOM;
    case ComparisonResult.GREATER:
      return AnimationTypeForLegendElement.MOVE_IN_FROM_TOP;
    case ComparisonResult.EQUAL:
    default:
      return AnimationTypeForLegendElement.NO_ANIMATION;
  }
};

export interface AnimationState {
  repayments: AnimationTypeForLegendElement;
  expenses: AnimationTypeForLegendElement;
  remaining: AnimationTypeForLegendElement;
}

export const defaultAnimationState: AnimationState = {
  repayments: AnimationTypeForLegendElement.NO_ANIMATION,
  expenses: AnimationTypeForLegendElement.NO_ANIMATION,
  remaining: AnimationTypeForLegendElement.NO_ANIMATION,
};
