import React from 'react';
import styled from 'styled-components';
import {
  spacingHelper,
  paletteHelper,
  Button,
  ArrowUpMd,
  ArrowDownMd,
  FigCaption,
} from '@construct-kit/core';

const Container = styled.div`
  padding: ${spacingHelper('extraSmall medium')};
`;

const Mask = styled.div`
  height: 40px;
  position: relative;
  top: -48px;
  margin-bottom: -48px;
  margin-left: -16px;
  margin-right: -16px;
  z-index: 999;
  background: linear-gradient(
    rgba(238, 238, 238, 0.0001) 0%,
    ${paletteHelper('backgroundPrimary')} 100%
  );
`;

const LabelContainer = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
`;

const StyledButtonLabel = styled(FigCaption)`
  margin-right: ${spacingHelper('twoExtraSmall')};
  font-weight: 600;
`;

interface Props {
  isExpand: boolean;
  onClick: () => void;
}

const ExpandButton = ({ isExpand, onClick }: Props) => {
  return (
    <Container>
      {!isExpand && <Mask />}
      <Button
        iconPlacement="right"
        variant="link-primary"
        sizeVariant="small"
        onClick={onClick}
      >
        <LabelContainer>
          <StyledButtonLabel>
            {isExpand ? 'Show less details' : 'Show all details'}
          </StyledButtonLabel>
          {isExpand ? <ArrowUpMd /> : <ArrowDownMd />}
        </LabelContainer>
      </Button>
    </Container>
  );
};

export default ExpandButton;
