// We can use this object to describe how the UI should look when missing data or there is a UI error

import {
  AffordabilityStatus,
  LoanCalculator,
  PurchaseIntent,
  RepaymentType,
} from '../@types/__generated__/graphql';

const deposit = {
  availableDeposit: {
    availableAmount: '$-',
    formattedRatio: '(0%)',
  },
  description: 'Available deposit $-',
  amount: {
    value: 0,
    formatted: '$-',
    slider: {
      minValue: 0,
      maxValue: 1,
      step: 1,
      stepIncrement: 1,
    },
  },
  upfrontCosts: {
    governmentFees: '$-',
    stampDuty: '$-',
    transferFee: '$-',
  },
};

const estimatedPropertyPrice = {
  amount: {
    value: 0,
    formatted: '$-',
    slider: {
      minValue: 0,
      maxValue: 1,
      step: 1,
      stepIncrement: 1,
    },
  },
  formattedAvailableDeposit: '$-',
  formattedLmiEstimate: '$-',
  formattedLoanAmount: '$-',
};

// TODO - in the cards related to the error states, this object must be checked to match current designs
export const errorState: LoanCalculator = {
  calculatorResults: {
    detailsModal: {
      aboutYou: {
        isFirstHomeBuyer: true,
        purchaseIntent: PurchaseIntent.ToLiveIn,
      },
      estimatedMonthlyLivingExpenses: undefined,
      estimatedMonthlyNetIncome: undefined,
      preferredLoanDetails: {
        loanTerm: 30,
        loanTermSlider: {
          maxValue: 30,
          minValue: 0,
          step: 1,
          stepIncrement: 1,
        },
        repaymentType: RepaymentType.PrincipalAndInterest,
      },
    },
    launcherView: {
      title: {
        text: 'Find out if you can afford this property',
      },
      description:
        'Understand your monthly repayments to see if this property is right for you.',
      affordabilityStatus: AffordabilityStatus.Default,
    },
    calculatorView: {
      calculatorHeading: 'Assess your affordability',
      affordabilityStatus: AffordabilityStatus.Default,
      higherDepositWarning: { message: '' },
      estimatedPropertyPrice,
      deposit,
      interestRate: {
        amount: {
          slider: {
            minValue: 0,
            maxValue: 1,
            step: 1,
            stepIncrement: 1,
          },
          value: 0,
        },
      },
      monthlyBudget: {
        repayments: {
          ratio: 33,
          filled: false,
          formatted: '$-',
        },
        expenses: {
          ratio: 33,
          filled: false,
          formatted: '$-',
        },
        remaining: {
          ratio: 33,
          filled: false,
          formatted: '$-',
        },
      },
      nextSteps: {
        affordabilityStatus: AffordabilityStatus.Default,
        primaryContent: {
          title: 'Need help with a home loan?',
          subtitle:
            'Our experts can help you connect with the right broker. Book an appointment with a loan expert.',
          buttonText: 'Speak to an expert',
        },
      },
      yourDetails: {
        aboutYou: {
          firstHomeBuyer: 'First home buyer',
          purchaseIntent: 'Owner occupier"',
        },
        estimatedMonthlyLivingExpenses: '$0/m',
        estimatedMonthlyNetIncome: '$0/m',
        preferredLoanDetails: {
          loanTerm: '30 years',
          repaymentType: 'Principal & interest',
        },
      },
      instruction: {
        howCalculateButton: {
          calculatorDetails: {
            deposit,
            loanAmount: estimatedPropertyPrice,
          },
          text: 'How we calculate this',
        },
        operationButton: {
          text: 'How this works',
        },
      },
    },
  },
  tooltips: {
    deposit: {
      content: '',
      title: '',
    },
    depositMobile: {
      content: '',
      title: '',
    },
    estimatedExpenses: {
      content: '',
      title: '',
    },
    estimatedIncome: {
      content: '',
      title: '',
    },
    governmentFees: {
      content: '',
      title: '',
    },
    interestRate: {
      content: '',
      title: '',
    },
    lmiEstimate: {
      content: '',
      title: '',
    },
    loanTerm: {
      content: '',
      title: '',
    },
    monthlyBudget: {
      content:
        'We use your income and expenses to calculate how much remains each month after you make loan repayments.',
      title: 'Your monthly budget',
    },
    propertyPrice: {
      content: '',
      title: '',
    },
    repaymentType: {
      content: '',
      title: '',
    },
    stampDuty: {
      content: '',
      title: '',
    },
    transferFee: {
      content: '',
      title: '',
    },
  },
  estimatedPropertyPriceDescription: 'Listed price $-',
};
