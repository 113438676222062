export const parseNumericString = (
  str: string,
  max?: number,
  min?: number,
  suffix?: string,
  fractionDigits?: number
): string => {
  const extractedNumericString = str.replace(
    /^[^\d.]*([0-9]+(?:\.[0-9]*)?).*$/g,
    '$1'
  );

  const numericValue = parseFloat(extractedNumericString);

  if (isNaN(numericValue)) {
    return `0${suffix || ''}`;
  }

  let formattedValue = extractedNumericString;

  const desiredValue = numericValue.toFixed(fractionDigits);

  const hasDecimal = formattedValue.split('.').length > 1;

  if (hasDecimal && !fractionDigits) {
    formattedValue = desiredValue;
  }

  // Has suffix means that value changes after onB
  if (suffix && hasLessThanFractionDigits(numericValue, fractionDigits)) {
    formattedValue = desiredValue;
  }

  if (hasMoreThanFractionDigits(numericValue, fractionDigits)) {
    formattedValue = desiredValue;
  }

  if (max !== undefined && numericValue > max) {
    formattedValue = max.toFixed(fractionDigits);
  }

  if (min !== undefined && numericValue < min) {
    formattedValue = min.toFixed(fractionDigits);
  }

  return `${formattedValue}${suffix || ''}`;
};

const hasMoreThanFractionDigits = (
  value: number,
  fractionDigits?: number
): boolean => {
  const valueStr = value.toString();
  const decimalPart = valueStr.split('.')[1];
  return (
    fractionDigits !== undefined &&
    decimalPart !== undefined &&
    decimalPart.length > fractionDigits
  );
};

const hasLessThanFractionDigits = (
  value: number,
  fractionDigits?: number
): boolean => {
  const valueStr = value.toString();
  const decimalPart = valueStr.split('.')[1];
  return (
    fractionDigits !== undefined &&
    (decimalPart == undefined || decimalPart.length < fractionDigits)
  );
};
