import { TextInput, TextInputSize, paletteHelper } from '@construct-kit/core';
import React, { useState } from 'react';
import styled from 'styled-components';

import { TextInputProps } from './types';

// https://git.realestate.com.au/financial-experiences/broker-carousel-components/blob/830d0724c51a43a74407b63362a4052c54b4bf83/src/components/CallBackForm/styles.ts#L38
export const ResetTextInput = styled(TextInput)`
  .input[type='text'] {
    font-size: 1rem;
    line-height: 1.5rem;
    border: none;
    padding: 0px;
    margin-bottom: 0.5rem;
    background-color: ${paletteHelper('backgroundPrimaryBase')};
  }

  color: ${paletteHelper('textPrimary')};
`;

const CustomTextInput = (props: TextInputProps) => {
  const {
    label,
    placeholder,
    value,
    id,
    onChange,
    onBlur,
    errorMessage = '',
    isValid = true,
    type = 'text',
    autoValidate = false,
    disabled = false,
    hideLabel = true,
    sizeVariant = 'medium',
    showError = false,
    ...extraProps
  } = props;

  const [touched, setTouched] = useState(false);

  return (
    <ResetTextInput
      dangerouslySetClassNames={{ input: 'input fs-exclude' }}
      disabled={disabled}
      id={id}
      label={label}
      type={type}
      autoValidate={autoValidate}
      placeholder={placeholder}
      value={value}
      sizeVariant={sizeVariant as TextInputSize}
      hideLabel={hideLabel}
      onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value);
      }}
      onBlur={() => {
        !touched && setTouched(true);
        onBlur && onBlur();
      }}
      errorMessage={(touched || showError) && !isValid ? errorMessage : ''}
      {...extraProps}
    />
  );
};

export default CustomTextInput;
