import React, { useEffect, useState } from 'react';
import { Button, Skeleton, Stack } from '@construct-kit/core';
import { trackEvent as trackEventFun } from '@argonaut/analytics';

import {
  StyledDivider,
  StyledInline,
  ValueText,
  LabelText,
  HeaderText,
  StyledCard,
} from './styles';
import { errorState } from '../../graphql/errorState';
import { useLoanCalculatorData } from '../../hooks/useLoanCalculatorContext';
import { AlertType } from '../AlertBox';
import { LoanCalculator } from '../../@types/__generated__/graphql';
import { getFinxModalOpenEventDataGetter } from '../../tracking/eventData/modalOpen';
import { TrackEventProps } from '../../tracking/types';

export const formNameForYourDetailsModal = 'your_details';

export type YourDetailsProps = {
  setIsYourDetailsModalOpen: (isOpen: boolean) => void;
};

export const YourDetails = ({
  trackEvent = trackEventFun,
  setIsYourDetailsModalOpen,
}: TrackEventProps & YourDetailsProps) => {
  const { loanCalculator, fetching, error, alertType, isInitialization } =
    useLoanCalculatorData();

  const [loanCalculatorData, setLoanCalculatorData] = useState<LoanCalculator>(
    error || !loanCalculator ? errorState : loanCalculator
  );

  useEffect(() => {
    if (loanCalculator && AlertType.RefetchError !== alertType) {
      setLoanCalculatorData(loanCalculator);
    }
  }, [alertType, loanCalculator]);

  const yourDetailsData =
    loanCalculatorData.calculatorResults.calculatorView.yourDetails;

  const details = [
    {
      label: 'Income',
      valueForFirstRow: yourDetailsData.estimatedMonthlyNetIncome,
    },
    {
      label: 'Expenses',
      valueForFirstRow: yourDetailsData.estimatedMonthlyLivingExpenses,
    },
    {
      label: 'Preferred loan',
      valueForFirstRow: yourDetailsData.preferredLoanDetails.repaymentType,
      valueForSecondRow: yourDetailsData.preferredLoanDetails.loanTerm,
    },
    {
      label: 'About you',
      valueForFirstRow: yourDetailsData.aboutYou.purchaseIntent,
      valueForSecondRow: yourDetailsData.aboutYou.firstHomeBuyer,
    },
  ];

  const onEditClick = () => {
    setIsYourDetailsModalOpen(true);
    trackEvent(getFinxModalOpenEventDataGetter(formNameForYourDetailsModal));
  };

  return (
    <StyledCard borderRadius="medium">
      <Stack gap="0">
        <StyledInline justifyContent="space-between" grow={false}>
          <HeaderText variant="subtitle01">Your details</HeaderText>
          <Button
            variant="link-primary"
            sizeVariant="medium"
            disabled={
              fetching ||
              alertType === AlertType.InitialisationError ||
              alertType === AlertType.HigherDepositWarning
            }
            onClick={onEditClick}>
            Edit
          </Button>
        </StyledInline>
        {details.map((detail, index) => (
          <React.Fragment key={index}>
            <StyledDivider />
            <DetailItem
              label={detail.label}
              valueForFirstRow={detail.valueForFirstRow}
              valueForSecondRow={detail.valueForSecondRow}
              loading={fetching && isInitialization}
            />
          </React.Fragment>
        ))}
      </Stack>
    </StyledCard>
  );
};

const DetailItem = ({
  label,
  valueForFirstRow,
  valueForSecondRow,
  loading,
}: {
  label: string;
  valueForFirstRow: string;
  valueForSecondRow?: string;
  loading: boolean;
}) => (
  <StyledInline
    justifyContent="space-between"
    alignItems="flex-start"
    grow={false}>
    <LabelText variant="body02">{label}</LabelText>
    <Skeleton loading={loading}>
      <ValueText variant="subtitle02">
        {valueForFirstRow}
        <br />
        {valueForSecondRow}
      </ValueText>
    </Skeleton>
  </StyledInline>
);
