import { CombinedError, useMutation } from 'urql';

import {
  SaveCalculatorProfilesMutation,
  SaveCalculatorProfilesMutationVariables,
} from '../@types/__generated__/graphql';
import { saveCalculatorProfiles } from '../graphql/mutation/saveCalculatorProfiles';
import { logError } from '../utils/errorHandler';
import { LOAN_CALCULATOR_COMPONENT_NAME } from '../entries/nexus-index';

export const useSaveCalculatorProfiles = (): {
  error: CombinedError | undefined;
  save: (variables: Partial<SaveCalculatorProfilesMutationVariables>) => void;
} => {
  const [response, execute] = useMutation<
    SaveCalculatorProfilesMutation,
    SaveCalculatorProfilesMutationVariables
  >(saveCalculatorProfiles);

  const save = (
    variables: Partial<SaveCalculatorProfilesMutationVariables>
  ) => {
    execute({ ...defaultSaveCalculatorProfilesVariables, ...variables }).catch(
      (error) => {
        logError(`Failed to save calculator profile, error: ${error}`, {
          component: LOAN_CALCULATOR_COMPONENT_NAME,
        });
      }
    );
  };

  const error = response.error;
  return { error, save };
};

export const defaultSaveCalculatorProfilesVariables: SaveCalculatorProfilesMutationVariables =
  {
    profile: {},
    includePreference: false,
    includePurchasePrice: false,
    includeProfile: false,
    listingId: null,
    purchasePrice: null,
    calcPreferences: {},
  };
