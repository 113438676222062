import { mediaQueryHelper, spacingHelper } from '@construct-kit/core';
import styled from 'styled-components';

export const ContentContainer = styled.div<{ $hasSubtext: boolean }>`
  width: 100%;

  display: grid;
  grid-gap: ${spacingHelper('extraSmall')};
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  grid-template-areas:
    'title title'
    'subplacement cta';

  ${mediaQueryHelper('tablet')} {
    ${({ $hasSubtext }) =>
      $hasSubtext
        ? `
      grid-template-areas:
        'title indicator cta'
        'subtext indicator cta';
    `
        : `
      grid-template-areas:
        'title indicator cta'
        'title indicator cta';
    `}

    grid-template-columns: auto auto auto;
    grid-template-rows: auto auto;
  }
`;

const GridItem = styled.div`
  display: flex;
  align-items: center;
`;

export const GridTitle = styled(GridItem)`
  grid-area: title;
`;

export const GridSubtext = styled(GridItem)`
  grid-area: subplacement;

  ${mediaQueryHelper('tablet')} {
    grid-area: subtext;
  }
`;

export const GridIndicator = styled(GridItem)`
  grid-area: subplacement;

  ${mediaQueryHelper('tablet')} {
    grid-area: indicator;
  }
`;

export const GridCTA = styled(GridItem)`
  grid-area: cta;
  margin-left: auto;
`;
