export const PoweredByMC = () => {
  return (
    <div>
      <svg
        width="155"
        height="16"
        viewBox="0 0 155 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_10096_5552)">
          <path
            d="M8.01184 5.3685C8.01184 5.3685 11.2847 0.800513 14.741 0.800513C15.89 0.800513 16.6693 1.34512 16.7487 2.1039C16.8282 2.86268 16.3759 3.86011 14.8174 3.75915C13.7264 3.68878 9.9279 3.05544 7.5229 7.46739C7.5229 7.46739 7.35788 4.59442 5.86354 2.91164C5.07205 2.01824 5.15456 1.18908 5.94605 0.699546C5.94605 0.699546 7.27537 0.0203141 7.68792 2.06107C7.87127 2.97895 8.15242 4.4139 8.01184 5.3685Z"
            fill="#5AE2E0"
          />
          <path
            d="M7.10598 9.30872C7.10598 9.30872 9.26345 4.32157 15.2255 5.12625C16.2309 5.26087 16.8819 5.94622 16.7932 6.96201C16.6893 8.09406 15.3997 8.35107 14.2904 8.04817C10.5378 7.01708 8.97009 7.48826 6.79122 10.7161C6.08224 6.73866 3.57639 6.16345 2.78797 4.95797C2.53433 4.57246 2.38459 3.85651 2.83381 3.42817C3.04322 3.21115 3.32534 3.07925 3.62597 3.05779C3.92661 3.03633 4.22456 3.12683 4.46261 3.3119C5.48329 4.09822 6.93179 7.43625 7.10598 9.30872Z"
            fill="#E4002B"
          />
          <path
            d="M6.1365 14.0855C6.1365 14.0855 6.30458 10.056 1.10341 8.40687C0.290535 8.14681 -0.210635 7.18303 0.143851 6.49768C0.498337 5.81233 1.43345 5.72054 2.31049 6.16112C5.40614 7.72152 6.44515 12.1212 6.44515 12.1212C9.04573 8.26307 13.1009 8.75567 14.8856 9.22685C16.6702 9.69802 16.9942 10.8851 16.6886 11.6959C16.383 12.5067 15.3837 12.9718 13.5777 12.0509C13.4187 11.9774 8.51706 9.32781 6.1365 14.0855Z"
            fill="#006875"
          />
          <path
            d="M30.3571 0.494873H32.6215V12.7853H30.2165V4.3102L26.855 8.93326H26.4516L23.0351 4.3102V12.7853H20.6484V0.494873H22.9129L26.6716 5.66254L30.3571 0.494873Z"
            fill="#006875"
          />
          <path
            d="M34.1592 8.24784C34.1592 5.45442 36.1425 3.52075 38.8133 3.52075C41.4842 3.52075 43.5011 5.45442 43.5011 8.24784C43.5011 11.0413 41.5148 12.9963 38.8133 12.9963C36.1119 12.9963 34.1592 11.0627 34.1592 8.24784ZM41.1878 8.24784C41.1878 6.52528 40.1702 5.54009 38.8195 5.54009C37.4687 5.54009 36.4817 6.52528 36.4817 8.24784C36.4817 9.97039 37.484 11.0015 38.8195 11.0015C40.1549 11.0015 41.1817 9.97957 41.1817 8.24784H41.1878Z"
            fill="#006875"
          />
          <path
            d="M49.8968 3.52087V5.94714H48.9403C47.8861 5.94714 47.1312 6.70286 47.1312 8.03685V12.7853H44.876V3.73198H47.1404V5.03231C47.3269 4.58115 47.644 4.19615 48.0508 3.92681C48.4576 3.65747 48.9355 3.5161 49.4232 3.52087H49.8968Z"
            fill="#006875"
          />
          <path
            d="M56.2927 5.53989H54.2728V9.51737C54.2728 10.3771 54.6242 10.8177 55.3271 10.8177H56.2927V12.7697H55.052C53.1207 12.7697 52.0145 11.5459 52.0145 9.6214V5.53989H50.3643V3.73166H51.9961V1.99075L54.2606 0.742432V3.73166H56.2805L56.2927 5.53989Z"
            fill="#006875"
          />
          <path
            d="M63.4928 3.73141H65.748V11.6068C65.748 14.4002 63.8686 16.0188 61.1122 16.0188C60.2593 16.0348 59.4193 15.8089 58.6893 15.3671C57.9592 14.9253 57.369 14.2857 56.9867 13.5221L58.6705 12.3962C59.1992 13.363 60.0946 14.0331 61.1641 14.0331C62.4293 14.0331 63.4805 13.2774 63.4805 11.7292V10.6583C63.2058 11.1029 62.8186 11.4669 62.3581 11.7135C61.8977 11.9601 61.3804 12.0806 60.8586 12.0627C58.6277 12.0627 56.9775 10.2269 56.9775 7.77926C56.9775 5.33158 58.6277 3.49582 60.8586 3.49582C61.3841 3.47562 61.9055 3.59697 62.3683 3.84721C62.8311 4.09745 63.2184 4.46742 63.4897 4.91853L63.4928 3.73141ZM63.5294 7.7915C63.5294 6.43916 62.6524 5.52433 61.4239 5.52433C60.1954 5.52433 59.2848 6.44221 59.2848 7.7915C59.2848 9.14078 60.1649 10.0587 61.4239 10.0587C62.6829 10.0587 63.5294 9.14384 63.5294 7.7915Z"
            fill="#006875"
          />
          <path
            d="M75.3347 7.08825V12.7852H73.2262V11.9071C72.6822 12.6261 71.7532 12.9963 70.5583 12.9963C68.4528 12.9963 67.0654 11.9408 67.0654 10.1662C67.0654 8.45895 68.5048 7.35137 70.7173 7.35137C71.578 7.30206 72.4248 7.58575 73.0825 8.14381V7.31772C73.0825 6.09388 72.3797 5.54009 71.2031 5.54009C70.7542 5.52248 70.3121 5.65479 69.9464 5.91621C69.5808 6.17763 69.3123 6.55333 69.1832 6.98422L67.3038 6.19178C67.915 4.27647 69.501 3.52075 71.2918 3.52075C73.4554 3.52075 75.3347 4.64669 75.3347 7.08825ZM73.1223 10.1479C73.1223 9.37377 72.3674 8.92401 71.2612 8.92401C70.155 8.92401 69.4154 9.36765 69.4154 10.1479C69.4154 10.928 70.1702 11.3442 71.2765 11.3442C72.3827 11.3442 73.1223 10.9219 73.1223 10.1479Z"
            fill="#006875"
          />
          <path
            d="M83.1363 3.73163H85.4008V11.607C85.4008 14.4005 83.5214 16.019 80.7649 16.019C79.9121 16.0347 79.0722 15.8086 78.3423 15.3668C77.6123 14.9251 77.022 14.2857 76.6394 13.5224L78.3232 12.3964C78.8519 13.3633 79.7473 14.0333 80.8169 14.0333C82.082 14.0333 83.1363 13.2776 83.1363 11.7294V10.6586C82.86 11.1045 82.4708 11.4694 82.0082 11.716C81.5455 11.9627 81.0259 12.0824 80.5021 12.0629C78.2713 12.0629 76.6211 10.2272 76.6211 7.77949C76.6211 5.33181 78.2713 3.49604 80.5021 3.49604C81.0281 3.47594 81.5499 3.5973 82.0131 3.84751C82.4764 4.09771 82.8642 4.46762 83.1363 4.91876V3.73163ZM83.1669 7.79172C83.1669 6.43938 82.2898 5.52456 81.0613 5.52456C79.8329 5.52456 78.9222 6.44244 78.9222 7.79172C78.9222 9.14101 79.7992 10.0589 81.0613 10.0589C82.3234 10.0589 83.1669 9.14407 83.1669 7.79172Z"
            fill="#006875"
          />
          <path
            d="M95.4327 8.75488H89.0581C89.1986 10.3551 90.0421 11.0588 91.1972 11.0588C92.2668 11.0588 93.0308 10.5662 93.5136 9.33621L95.4297 10.0369C94.7268 12.0776 93.1286 12.9924 91.2858 12.9924C88.615 12.9924 86.7539 11.0924 86.7539 8.24393C86.7539 5.39544 88.5875 3.51685 91.1789 3.51685C93.6908 3.51685 95.4572 5.4138 95.4572 8.22863C95.448 8.38773 95.448 8.59884 95.4327 8.75488ZM89.1284 7.22508H93.1499C92.9024 5.94311 92.1843 5.36179 91.1819 5.36179C90.0757 5.34649 89.3728 5.96147 89.1284 7.22508Z"
            fill="#006875"
          />
          <path
            d="M99.8984 6.63229C99.8984 2.85062 102.462 0.268311 106.062 0.268311C108.715 0.268311 110.768 1.67267 111.563 4.18766L109.369 4.9954C108.822 3.43194 107.645 2.46511 106.065 2.46511C103.948 2.46511 102.42 4.02856 102.42 6.63229C102.42 9.23601 103.948 10.7964 106.071 10.7964C107.651 10.7964 108.822 9.83263 109.375 8.26611L111.56 9.06161C110.768 11.5766 108.715 12.984 106.059 12.984C102.462 12.9963 99.8984 10.4109 99.8984 6.63229Z"
            fill="#006875"
          />
          <path
            d="M120.626 6.94757V12.7853H118.362V7.33614C118.362 6.1582 117.692 5.50038 116.782 5.50038C115.709 5.50038 114.948 6.22245 114.948 7.47077V12.7853H112.684V0.494873H114.948V4.90988C115.188 4.46841 115.548 4.1038 115.986 3.85805C116.424 3.61229 116.922 3.4954 117.423 3.52082C119.257 3.52082 120.626 4.87316 120.626 6.94757Z"
            fill="#006875"
          />
          <path
            d="M121.913 8.24784C121.913 5.45442 123.896 3.52075 126.567 3.52075C129.238 3.52075 131.255 5.45442 131.255 8.24784C131.255 11.0413 129.269 12.9963 126.561 12.9963C123.854 12.9963 121.913 11.0627 121.913 8.24784ZM128.942 8.24784C128.942 6.52528 127.924 5.54009 126.573 5.54009C125.223 5.54009 124.236 6.52528 124.236 8.24784C124.236 9.97039 125.238 11.0015 126.573 11.0015C127.909 11.0015 128.936 9.97957 128.936 8.24784H128.942Z"
            fill="#006875"
          />
          <path
            d="M132.312 1.33937C132.336 0.968642 132.5 0.620947 132.771 0.366884C133.042 0.112821 133.399 -0.0285645 133.77 -0.0285645C134.141 -0.0285645 134.498 0.112821 134.769 0.366884C135.04 0.620947 135.204 0.968642 135.228 1.33937C135.228 2.20218 134.58 2.69478 133.77 2.69478C132.96 2.69478 132.312 2.20218 132.312 1.33937ZM134.895 3.73198V12.7853H132.63V3.73198H134.895Z"
            fill="#006875"
          />
          <path
            d="M136.267 8.24784C136.267 5.41771 138.235 3.52075 140.795 3.52075C142.797 3.52075 144.518 4.52124 145.117 6.73639L143.115 7.45846C142.675 6.0694 141.938 5.52479 140.795 5.52479C139.653 5.52479 138.601 6.42126 138.601 8.24784C138.601 10.0744 139.656 10.9739 140.795 10.9739C141.935 10.9739 142.675 10.4477 143.115 9.05863L145.117 9.77764C144.505 11.9928 142.797 12.9963 140.795 12.9963C138.235 12.9963 136.267 11.078 136.267 8.24784Z"
            fill="#006875"
          />
          <path
            d="M154.55 8.75488H148.175C148.316 10.3551 149.159 11.0588 150.314 11.0588C151.387 11.0588 152.148 10.5662 152.634 9.33621L154.547 10.0369C153.844 12.0776 152.246 12.9924 150.403 12.9924C147.735 12.9924 145.874 11.0924 145.874 8.24393C145.874 5.39544 147.708 3.51685 150.299 3.51685C152.808 3.51685 154.577 5.4138 154.577 8.22863C154.577 8.40441 154.568 8.58006 154.55 8.75488ZM148.258 7.22508H152.279C152.035 5.94311 151.314 5.36179 150.314 5.36179C149.196 5.34649 148.493 5.96147 148.258 7.22508Z"
            fill="#006875"
          />
        </g>
        <defs>
          <clipPath id="clip0_10096_5552">
            <rect width="154.596" height="16" fill="white" />
          </clipPath>
        </defs>
      </svg>
    </div>
  );
};
