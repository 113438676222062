import { Modal, Stack } from '@construct-kit/core';
import { trackEvent as trackEventFun } from '@argonaut/analytics';

import { HeaderExpandedDialog, MobileCTAButton } from './styles';
import { Calculator } from '../Calculator';
import { MCLogo } from '../MCLogo';
import { useLoanCalculatorData } from '../../hooks/useLoanCalculatorContext';
import { getContactFormModalOpenEventDataGetter } from '../../tracking/eventData/modalOpen';
import { formNameForGotQuestions } from '../GotQuestionsModal';
import { TrackEventProps } from '../../tracking/types';

export const CalculatorModal = ({
  trackEvent = trackEventFun,
}: TrackEventProps) => {
  const {
    isCalculatorModalOpen,
    setIsCalculatorModalOpen,
    setIsContactFormModalOpen,
  } = useLoanCalculatorData();

  const closeModal = () => setIsCalculatorModalOpen(false);

  const onGotQuestionsClick = () => {
    setIsCalculatorModalOpen(false);
    setIsContactFormModalOpen(true);
    trackEvent(getContactFormModalOpenEventDataGetter(formNameForGotQuestions));
  };

  return (
    <Modal
      opened={isCalculatorModalOpen}
      onRequestClose={closeModal}
      getAppElement={() => document.getElementById('body')!}>
      <HeaderExpandedDialog
        id="calculatorModal"
        autoFullScreen
        onClose={closeModal}
        dangerouslySetClassNames={{
          header: 'calcModalHeader',
        }}
        header={
          <MobileCTAButton icon={<MCLogo />} onClick={onGotQuestionsClick}>
            Got questions? Tap here.
          </MobileCTAButton>
        }>
        <Stack inset="0" gap="extraSmall">
          <Calculator />
        </Stack>
      </HeaderExpandedDialog>
    </Modal>
  );
};
