import { DataGetter } from '@argonaut/analytics';

import { EventData } from '../types';

const EVENT_NAME_CALCULATOR_INTERACTION = 'home_loan_calculator_interaction';
const SOURCE_CALCULATOR_INTERACTION = 'buy_pdp';
const SCHEMA_CALCULATOR_INTERACTION =
  'iglu:au.com.realestate/home_loan_calculator_interaction/jsonschema/1-0-1';

export interface CalculatorInteractionEventData extends EventData {
  schema: string;
  data: {
    event_name: string;
    user_action: string;
    source: string;
    field_updated?: string;
  };
}

export const getCalculatorInteractionEventDataGetter =
  (userAction: string, fieldUpdated?: string): DataGetter =>
  (): CalculatorInteractionEventData => ({
    schema: SCHEMA_CALCULATOR_INTERACTION,
    data: {
      event_name: EVENT_NAME_CALCULATOR_INTERACTION,
      source: SOURCE_CALCULATOR_INTERACTION,
      user_action: userAction,
      field_updated: fieldUpdated,
    },
  });
