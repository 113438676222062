const parseMonetaryValue = (value: string): number => {
  // Handle special cases
  if (value === '$-') return Number.NEGATIVE_INFINITY;

  // Remove dollar signs and commas, then parse as float
  const cleanedValue = value.replace(/[,$]/g, '');
  return parseFloat(cleanedValue);
};

export enum ComparisonResult {
  LESS = -1,
  EQUAL = 0,
  GREATER = 1,
}

export const compareMonetaryValues = (
  a: string,
  b: string
): ComparisonResult => {
  const parsedA = parseMonetaryValue(a);
  const parsedB = parseMonetaryValue(b);

  if (parsedA < parsedB) return ComparisonResult.LESS;
  if (parsedA > parsedB) return ComparisonResult.GREATER;
  return ComparisonResult.EQUAL;
};
