import { createContext, ReactNode, useContext, useState } from 'react';
import { useMatchMedia } from '@construct-kit/core';

import { AppState, PropertyDetails } from './types';
import { useQueryCalculator } from './useQueryCalculator';
import { getScreenStatusFromUrl } from '../utils/getDefaultVariables';
import {
  FinancialDetailsInput,
  ListingDetailsInput,
  LoanCalculator,
  LoanCalculatorCalculatorResultsArgs,
  State,
} from '../@types/__generated__/graphql';
import { transformLoanCalculatorToFinancialDetailsInput } from '../utils/transformLoanCalculatorToVariables';
import { AlertType } from '../components/AlertBox';

export const LoanCalculatorContext = createContext<AppState | undefined>(
  undefined
);

export interface LoanCalculatorProviderProps {
  listingDetails: ListingDetailsInput;
  propertyDetails?: PropertyDetails;
  isLargeScreen?: boolean;
  children: ReactNode;
}

export const LoanCalculatorProvider: React.FC<LoanCalculatorProviderProps> = ({
  listingDetails,
  propertyDetails,
  isLargeScreen,
  children,
}) => {
  const initialVariables = { listingDetails: listingDetails };

  const [currentVariables, setCurrentVariables] =
    useState<LoanCalculatorCalculatorResultsArgs>(initialVariables);
  const [isInitialization, setIsInitialization] = useState(true);
  const [isCalculatorModalOpen, setIsCalculatorModalOpen] = useState(false);
  const [isContactFormModalOpen, setIsContactFormModalOpen] = useState(false);
  const [previousLoanCalculatorData, setPreviousLoanCalculatorData] = useState<
    LoanCalculator | undefined
  >(undefined);

  const mediaLargeScreen = useMatchMedia('medium');

  const screenStatus = getScreenStatusFromUrl();

  const { result, reexecuteQuery } = useQueryCalculator(currentVariables);

  const { fetching, data, error } = result;

  const reFetchFunction = (
    updates: Partial<FinancialDetailsInput>,
    state?: State
  ) => {
    setIsInitialization(false);
    setPreviousLoanCalculatorData(data?.loanCalculator);
    const financialDetails = loanCalculatorData
      ? transformLoanCalculatorToFinancialDetailsInput(loanCalculatorData)
      : null;

    setCurrentVariables((currentVariables) => ({
      listingDetails: {
        ...currentVariables.listingDetails,
        state: state ?? currentVariables.listingDetails.state,
      },
      financialDetails: {
        ...financialDetails,
        ...updates,
      },
    }));
    reexecuteQuery();
  };

  const loanCalculatorData = data?.loanCalculator;

  const hasError = !!error || screenStatus.error;
  const isFetching = fetching || screenStatus.loading;

  const getAlertType = (): AlertType => {
    if (hasError) {
      return isInitialization
        ? AlertType.InitialisationError
        : AlertType.RefetchError;
    } else if (
      loanCalculatorData?.calculatorResults.calculatorView.higherDepositWarning
    ) {
      return AlertType.HigherDepositWarning;
    } else {
      return AlertType.NULL;
    }
  };

  const value: AppState = {
    loanCalculator: loanCalculatorData,
    previousLoanCalculatorData,
    isLargeScreen: isLargeScreen ?? mediaLargeScreen,
    isCalculatorModalOpen: isCalculatorModalOpen,
    isContactFormModalOpen: isContactFormModalOpen,
    setIsCalculatorModalOpen: setIsCalculatorModalOpen,
    setIsContactFormModalOpen: setIsContactFormModalOpen,
    fetching: isFetching,
    error: hasError,
    refetch: reFetchFunction,
    queryVariables: currentVariables,
    propertyDetails,
    alertType: getAlertType(),
    isInitialization,
  };

  return (
    <LoanCalculatorContext.Provider value={value}>
      {children}
    </LoanCalculatorContext.Provider>
  );
};

export const useLoanCalculatorData = () => {
  const context = useContext(LoanCalculatorContext);
  if (context === undefined) {
    throw new Error(
      'useLoanCalculatorData must be used within a LoanCalculatorProvider'
    );
  }
  return context;
};
