import { TableItem } from '@rea-group/fs-quick-lead-components/lib/types/components/forms/QuickLeadsWithDetails/DetailsTable';

import {
  FinancialDetailsInput,
  LoanCalculator,
} from '../@types/__generated__/graphql';

export const transformLoanCalculatorToFinancialDetailsInput = (
  loanCalculator: LoanCalculator
): FinancialDetailsInput => {
  const calculatorView = loanCalculator.calculatorResults.calculatorView;
  const details = loanCalculator.calculatorResults.detailsModal;

  const financialDetails: FinancialDetailsInput = {
    deposit: calculatorView.deposit.amount.value,
    estimatedMonthlyLivingExpenses: details.estimatedMonthlyLivingExpenses,
    estimatedMonthlyNetIncome: details.estimatedMonthlyNetIncome,
    interestRate: calculatorView.interestRate.amount.value,
    isFirstHomeBuyer: details.aboutYou.isFirstHomeBuyer,
    loanTerm: details.preferredLoanDetails.loanTerm,
    purchaseIntent: details.aboutYou.purchaseIntent,
    purchasePrice: calculatorView.estimatedPropertyPrice.amount.value,
    repaymentType: details.preferredLoanDetails.repaymentType,
  };

  return financialDetails;
};

export const transformLoanCalculatorToTableItems = (
  loanCalculator: LoanCalculator
): TableItem[] => {
  const { calculatorResults } = loanCalculator;
  const yourDetails = calculatorResults.calculatorView.yourDetails;

  const deposit = calculatorResults.calculatorView.deposit.amount.formatted;
  const estimatedMonthlyNetIncome = yourDetails.estimatedMonthlyNetIncome;
  const estimatedMonthlyLivingExpenses =
    yourDetails.estimatedMonthlyLivingExpenses;
  const repaymentType = yourDetails.preferredLoanDetails.repaymentType;
  const loanTerm = yourDetails.preferredLoanDetails.loanTerm;
  const firstHomeBuyer = yourDetails.aboutYou.firstHomeBuyer;
  const propertyUse = yourDetails.aboutYou.purchaseIntent;
  return [
    { title: 'Deposit', value: deposit || '$0' },
    { title: 'Income', value: estimatedMonthlyNetIncome },
    { title: 'Expenses', value: estimatedMonthlyLivingExpenses },
    { title: 'Repayment type', value: repaymentType },
    { title: 'Loan term', value: loanTerm },
    { title: 'Property use', value: propertyUse },
    { title: 'First home buyer', value: firstHomeBuyer },
  ];
};
