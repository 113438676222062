import {
  Alert,
  Button,
  ButtonGroupButton,
  H2,
  H3,
  Inline,
  Modal,
} from '@construct-kit/core';
import { useState, useEffect, useMemo } from 'react';
import { trackEvent as trackEventFun } from '@argonaut/analytics';

import {
  LoanCalculator,
  PurchaseIntent,
  RepaymentType,
} from '../../@types/__generated__/graphql';
import {
  OverlaySpinner,
  SpinnerContainer,
  StyledBody,
  StyledDivider,
  YourDetailsDialog,
} from './styles';
import { UnsetButtonGroup } from '../styles';
import { formatEnumValue } from '../../utils/formatEnumValue';
import { SliderQuestion, SliderQuestionType } from '../SliderQuestion';
import { useLoanCalculatorData } from '../../hooks/useLoanCalculatorContext';
import { errorState } from '../../graphql/errorState';
import { useLoanCalculatorEventHandlers } from '../../hooks/useLoanCalculatorEventHandlers';
import { AlertType } from '../AlertBox';
import { getFinXFormInteractionEventDataGetter } from '../../tracking/eventData/formInteraction';
import { TrackEventProps } from '../../tracking/types';
import { handleKeyDown } from '../../utils/handleKeyDown';
import { UnsetCurrencyInput } from '../FormattedNumericInput/style';

type Props = {
  isOpen: boolean;
  closeModal: () => void;
} & TrackEventProps;

enum SubmitStatus {
  unSubmitted,
  submitted,
}

const sourceForSaveYourDetails = 'buy_pdp';
const actionForSaveYourDetails = 'saved';

export const YourDetailsModal = ({
  isOpen,
  closeModal,
  trackEvent = trackEventFun,
}: Props) => {
  const { loanCalculator, fetching, error, refetch, alertType } =
    useLoanCalculatorData();

  const { handleYourDetailsChange } = useLoanCalculatorEventHandlers({
    refetch,
  });

  const [loanCalculatorData, setLoanCalculatorData] = useState<LoanCalculator>(
    error || !loanCalculator ? errorState : loanCalculator
  );

  const [submitStatus, setSubmitStatus] = useState(SubmitStatus.unSubmitted);
  useEffect(() => {
    if (!fetching && submitStatus === SubmitStatus.submitted && !error) {
      closeModal();
      setSubmitStatus(SubmitStatus.unSubmitted);
    }
  }, [submitStatus, error, fetching, closeModal]);

  const detailsModalData = loanCalculatorData.calculatorResults.detailsModal;
  const tooltips = loanCalculatorData.tooltips;

  const { estimatedMonthlyLivingExpenses, estimatedMonthlyNetIncome } =
    detailsModalData;

  const { isFirstHomeBuyer, purchaseIntent } = detailsModalData.aboutYou;
  const { repaymentType, loanTerm } = detailsModalData.preferredLoanDetails;

  const [repaymentTypeLocalState, setRepaymentTypeLocalState] =
    useState(repaymentType);
  const [purchaseIntentLocalState, setPurchaseIntentLocalState] =
    useState(purchaseIntent);
  const [isFirstHomeBuyerLocalState, setIsFirstHomeBuyerLocalState] =
    useState(isFirstHomeBuyer);
  const [expensesLocalState, setExpensesLocalState] = useState(
    estimatedMonthlyLivingExpenses || ''
  );
  const [incomeLocalState, setIncomeLocalState] = useState(
    estimatedMonthlyNetIncome || ''
  );
  const [loanTermLocalState, setLoanTermLocalState] = useState(
    loanTerm ? loanTerm : 30
  );
  const [showExpensesError, setShowExpensesError] = useState(
    expensesLocalState === ''
  );
  const [showIncomeError, setShowIncomeError] = useState(
    incomeLocalState === ''
  );
  const [incomeErrorMessage, setincomeErrorMessage] = useState('');
  const [expensesErrorMessage, setExpensesErrorMessage] = useState('');

  const resetStateWithLoanCalculator = (loanCalculator: LoanCalculator) => {
    setLoanCalculatorData(loanCalculator);
    const detailsModalData = loanCalculator.calculatorResults.detailsModal;
    setRepaymentTypeLocalState(
      detailsModalData.preferredLoanDetails.repaymentType
    );
    setPurchaseIntentLocalState(detailsModalData.aboutYou.purchaseIntent);
    setIsFirstHomeBuyerLocalState(detailsModalData.aboutYou.isFirstHomeBuyer);

    setIncomeLocalState(detailsModalData.estimatedMonthlyNetIncome || '');
    setExpensesLocalState(
      detailsModalData.estimatedMonthlyLivingExpenses || ''
    );
    setLoanTermLocalState(detailsModalData.preferredLoanDetails.loanTerm || 30);

    setShowExpensesError(!detailsModalData.estimatedMonthlyLivingExpenses);
    setExpensesErrorMessage('');
    setShowIncomeError(!detailsModalData.estimatedMonthlyNetIncome);
    setincomeErrorMessage('');
  };

  useMemo(() => {
    if (loanCalculator && AlertType.RefetchError !== alertType) {
      resetStateWithLoanCalculator(loanCalculator);
    }
  }, [alertType, loanCalculator]);

  const updateStateAndSaveProfile = () => {
    handleYourDetailsChange(
      isFirstHomeBuyerLocalState,
      purchaseIntentLocalState,
      Number(incomeLocalState),
      Number(expensesLocalState),
      loanTermLocalState,
      repaymentTypeLocalState
    );
    setSubmitStatus(SubmitStatus.submitted);
  };

  const validateAndSetErrorMessage = () => {
    if (showExpensesError || showIncomeError) {
      showIncomeError
        ? setincomeErrorMessage('Enter your income')
        : setincomeErrorMessage('');
      showExpensesError
        ? setExpensesErrorMessage('Enter your living expenses')
        : setExpensesErrorMessage('');
    } else {
      updateStateAndSaveProfile();
      trackEvent(
        getFinXFormInteractionEventDataGetter(
          sourceForSaveYourDetails,
          actionForSaveYourDetails
        )
      );
    }
  };

  const handleIncomeOnBlur = (value: string) => {
    setIncomeLocalState(value);
    if (value === '') {
      setShowIncomeError(true);
    } else {
      setShowIncomeError(false);
      setincomeErrorMessage('');
    }
  };

  const handleExpensesOnBlur = (value: string) => {
    setExpensesLocalState(value);
    if (value === '') {
      setShowExpensesError(true);
    } else {
      setShowExpensesError(false);
      setExpensesErrorMessage('');
    }
  };

  const onModalClose = () => {
    closeModal();
    setSubmitStatus(SubmitStatus.unSubmitted);
    resetStateWithLoanCalculator(loanCalculator || errorState);
  };

  return (
    <Modal
      opened={isOpen}
      onRequestClose={onModalClose}
      getAppElement={() => 'body'}
      reactModalProps={{
        style: { content: { overflow: 'hidden' } },
      }}>
      <YourDetailsDialog
        header={<H2>Your details</H2>}
        onClose={onModalClose}
        autoFullScreen
        footer={
          <Inline gap="medium">
            <Button onClick={onModalClose} variant="outline">
              Cancel
            </Button>
            <Button
              onClick={() => {
                validateAndSetErrorMessage();
              }}>
              Calculate
            </Button>
          </Inline>
        }
        dangerouslySetClassNames={{
          header: 'detailsModalHeader',
        }}>
        {fetching ? (
          <SpinnerContainer>
            <OverlaySpinner />
          </SpinnerContainer>
        ) : (
          <StyledBody>
            {error && (
              <Alert variant="warning">
                Sorry, we&apos;re unable to save your details. Try again later.
              </Alert>
            )}
            <H3>Monthly income</H3>
            <UnsetCurrencyInput
              label={tooltips.estimatedIncome.title}
              tooltip={tooltips.estimatedIncome.content}
              tooltipProps={{ zIndex: 600 }}
              max={99999999}
              value={incomeLocalState !== '0' ? incomeLocalState : ''}
              onBlur={(e) => handleIncomeOnBlur(e.target.value)}
              onChange={(e) => {
                setIncomeLocalState(e.target.value);
              }}
              errorMessage={incomeErrorMessage}
              data-testid="income-input"
              onKeyDown={handleKeyDown}
            />
            <StyledDivider />
            <H3>Monthly expenses</H3>
            <UnsetCurrencyInput
              label={tooltips.estimatedExpenses.title}
              tooltip={tooltips.estimatedExpenses.content}
              tooltipProps={{ zIndex: 600 }}
              max={99999999}
              value={expensesLocalState !== '0' ? expensesLocalState : ''}
              onBlur={(e) => handleExpensesOnBlur(e.target.value)}
              onChange={(e) => setExpensesLocalState(e.target.value)}
              errorMessage={expensesErrorMessage}
              data-testid="expenses-input"
              onKeyDown={handleKeyDown}
            />
            <StyledDivider />
            <H3>Preferrred loan details</H3>
            <UnsetButtonGroup
              name="repaymentType"
              label={tooltips.repaymentType.title}
              tooltip={tooltips.repaymentType.content}
              tooltipProps={{ zIndex: 600 }}>
              <ButtonGroupButton
                value={RepaymentType.PrincipalAndInterest}
                label={formatEnumValue(RepaymentType.PrincipalAndInterest)}
                checked={
                  repaymentTypeLocalState === RepaymentType.PrincipalAndInterest
                }
                onChange={(e) =>
                  setRepaymentTypeLocalState(e.target.value as RepaymentType)
                }
              />
              <ButtonGroupButton
                value={RepaymentType.InterestOnly}
                label={formatEnumValue(RepaymentType.InterestOnly)}
                checked={repaymentTypeLocalState === RepaymentType.InterestOnly}
                onChange={(e) =>
                  setRepaymentTypeLocalState(e.target.value as RepaymentType)
                }
              />
            </UnsetButtonGroup>
            <SliderQuestion
              title={tooltips.loanTerm.title}
              sliderType={SliderQuestionType.LOAN_TERM}
              value={loanTermLocalState ? loanTermLocalState : 30}
              sliderValues={
                detailsModalData.preferredLoanDetails.loanTermSlider
              }
              fetching={fetching}
              titleTooltip={tooltips.loanTerm.content}
              tooltipProps={{ zIndex: 600 }}
              setLoanTerm={setLoanTermLocalState}
              disabled={fetching}
            />
            <StyledDivider />
            <H3>About you</H3>
            <UnsetButtonGroup
              name="purchaseIntent"
              label="Will you live in this property?">
              <ButtonGroupButton
                value={PurchaseIntent.ToLiveIn}
                label="Yes"
                checked={purchaseIntentLocalState === PurchaseIntent.ToLiveIn}
                onChange={(e) =>
                  setPurchaseIntentLocalState(e.target.value as PurchaseIntent)
                }
              />
              <ButtonGroupButton
                value={PurchaseIntent.Investment}
                label="No, it's an investment"
                checked={purchaseIntentLocalState === PurchaseIntent.Investment}
                onChange={(e) =>
                  setPurchaseIntentLocalState(e.target.value as PurchaseIntent)
                }
              />
            </UnsetButtonGroup>
            <UnsetButtonGroup
              name="firstHomeBuyer"
              label="Are you a first home buyer?">
              <ButtonGroupButton
                value="Yes"
                label="Yes"
                checked={isFirstHomeBuyerLocalState}
                onChange={() => setIsFirstHomeBuyerLocalState(true)}
              />
              <ButtonGroupButton
                label="No"
                value="No"
                checked={!isFirstHomeBuyerLocalState}
                onChange={() => setIsFirstHomeBuyerLocalState(false)}
              />
            </UnsetButtonGroup>
          </StyledBody>
        )}
      </YourDetailsDialog>
      ;
    </Modal>
  );
};
