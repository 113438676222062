import { useEffect, useState } from 'react';
import { Button, H2, Modal, Strong, Tooltip, Text } from '@construct-kit/core';
import { trackEvent as trackEventFn } from '@argonaut/analytics';

import { SliderQuestion, SliderQuestionType } from '../SliderQuestion';
import { errorState } from '../../graphql/errorState';
import {
  StyledDialog,
  DialogContent,
  PrimaryText,
  StyledInline,
  StyledDivider,
} from '../Calculator/styles';
import { useLoanCalculatorData } from '../../hooks/useLoanCalculatorContext';
import { useLoanCalculatorEventHandlers } from '../../hooks/useLoanCalculatorEventHandlers';
import { AlertType } from '../AlertBox';
import { LoanCalculator } from '../../@types/__generated__/graphql';
import { TrackEventProps } from '../../tracking/types';
import { getCalculatorInteractionEventDataGetter } from '../../tracking/eventData/calculatorInteraction';
import { Z_INDEX_HIGH_PRIORITY } from '../../constants';

const modalTypeForLoanAmountModal = 'loan_amount';

export const EstimatedPropertyPriceSection = ({
  trackEvent = trackEventFn,
}: TrackEventProps) => {
  const {
    loanCalculator,
    fetching,
    error,
    refetch,
    alertType,
    queryVariables,
    isInitialization,
  } = useLoanCalculatorData();

  const { handlePropertyPriceChange } = useLoanCalculatorEventHandlers({
    refetch,
  });

  const [
    isEstimatedPropertyPriceModalOpen,
    setEstimatedPropertyPriceModalOpen,
  ] = useState(false);

  const [loanCalculatorData, setLoanCalculatorData] = useState<LoanCalculator>(
    error || !loanCalculator ? errorState : loanCalculator
  );

  useEffect(() => {
    if (loanCalculator && AlertType.RefetchError !== alertType) {
      setLoanCalculatorData(loanCalculator);
    }
  }, [alertType, loanCalculator]);

  const listingId = queryVariables.listingDetails.listingId;

  const handleValueChange = (value: number, userAction: string) => {
    if (
      value ==
      loanCalculatorData.calculatorResults.calculatorView.estimatedPropertyPrice
        .amount.value
    ) {
      return;
    }
    setLoanCalculatorData((prev) => ({
      ...prev,
      calculatorResults: {
        ...prev.calculatorResults,
        calculatorView: {
          ...prev.calculatorResults.calculatorView,
          estimatedPropertyPrice: {
            ...prev.calculatorResults.calculatorView.estimatedPropertyPrice,
            amount: {
              ...prev.calculatorResults.calculatorView.estimatedPropertyPrice
                .amount,
              value,
            },
          },
        },
      },
    }));
    handlePropertyPriceChange(value, listingId);

    const fieldUpdated: string = 'home-loan-calculator:estimatedPropertyPrice';
    trackEvent(
      getCalculatorInteractionEventDataGetter(userAction, fieldUpdated)
    );
  };

  const estimatedPropertyPrice =
    loanCalculatorData.calculatorResults.calculatorView.estimatedPropertyPrice;

  const propertyPriceDescription =
    loanCalculatorData.estimatedPropertyPriceDescription;

  const tooltips = loanCalculatorData.tooltips;

  return (
    <>
      <SliderQuestion
        fetching={fetching}
        title="Estimated property price"
        value={estimatedPropertyPrice.amount.value}
        sliderValues={estimatedPropertyPrice.amount.slider}
        sliderType={SliderQuestionType.CURRENCY}
        subtitle={propertyPriceDescription}
        subtitleTooltip={tooltips.propertyPrice}
        sliderCTA={{
          modalCTAText:
            'Loan amount' + ' ' + estimatedPropertyPrice.formattedLoanAmount,
          onCTAClick: () => setEstimatedPropertyPriceModalOpen(true),
        }}
        refetchFunction={handleValueChange}
        disabled={alertType === AlertType.InitialisationError}
        trackEventModalType={modalTypeForLoanAmountModal}
        isInitialization={isInitialization}
      />
      <Modal
        opened={isEstimatedPropertyPriceModalOpen}
        onRequestClose={() => setEstimatedPropertyPriceModalOpen(false)}
        getAppElement={() => 'body'}>
        <StyledDialog
          header={<H2>Loan amount</H2>}
          onClose={() => setEstimatedPropertyPriceModalOpen(false)}
          autoFullScreen={true}>
          <DialogContent>
            <StyledInline>
              <Text>Purchase price</Text>
              <PrimaryText>
                {estimatedPropertyPrice.amount.formatted}
              </PrimaryText>
            </StyledInline>
            <StyledInline>
              <Text>Available deposit</Text>
              <PrimaryText>
                {estimatedPropertyPrice.formattedAvailableDeposit}
              </PrimaryText>
            </StyledInline>
            <StyledInline>
              <Tooltip
                trigger={({ toggle }) => (
                  <Button onClick={() => toggle()} variant="link-secondary">
                    {tooltips.lmiEstimate.title}
                  </Button>
                )}
                zIndex={Z_INDEX_HIGH_PRIORITY}>
                {tooltips.lmiEstimate.content}
              </Tooltip>
              <PrimaryText>
                {estimatedPropertyPrice.formattedLmiEstimate}
              </PrimaryText>
            </StyledInline>
            <StyledDivider />
            <StyledInline>
              <PrimaryText>Loan amount</PrimaryText>
              <PrimaryText>
                <Strong>{estimatedPropertyPrice.formattedLoanAmount}</Strong>
              </PrimaryText>
            </StyledInline>
          </DialogContent>
        </StyledDialog>
      </Modal>
    </>
  );
};
