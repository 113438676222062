import {
  FinancialDetailsInput,
  PropertyUse,
  PurchaseIntent,
  RepaymentType,
  State,
} from '../@types/__generated__/graphql';
import { isUserSignedIn } from '../utils/userHelper';
import { useSaveCalculatorProfiles } from './useSaveCalculatorProfiles';

export type RefetchFunction = (
  updates: Partial<FinancialDetailsInput>,
  state?: State
) => void;

interface UseLoanCalculatorEventHandlersProps {
  refetch: RefetchFunction;
}

const convertPurchaseIntentToPropertyUse = (
  intent: PurchaseIntent
): PropertyUse => {
  switch (intent) {
    case PurchaseIntent.Investment:
      return PropertyUse.Investment;
    case PurchaseIntent.ToLiveIn:
      return PropertyUse.ToLiveIn;
  }
};

export const useLoanCalculatorEventHandlers = ({
  refetch,
}: UseLoanCalculatorEventHandlersProps) => {
  const { save } = useSaveCalculatorProfiles();

  const handleDepositChange = (value: number) => {
    refetch({ deposit: value });

    if (isUserSignedIn()) {
      save({
        profile: {
          totalSavings: value,
        },
        includeProfile: true,
      });
    }
  };

  const handleInterestRateChange = (value: number) => {
    refetch({ interestRate: value });

    if (isUserSignedIn()) {
      save({
        includePreference: true,
        calcPreferences: {
          interestRate: value,
        },
      });
    }
  };

  const handlePropertyPriceChange = (value: number, listingId: string) => {
    refetch({ purchasePrice: value });

    if (isUserSignedIn()) {
      save({
        listingId: listingId,
        purchasePrice: value,
        includePurchasePrice: true,
      });
    }
  };

  const handleYourDetailsChange = (
    isFirstHomeBuyer: boolean,
    purchaseIntent: PurchaseIntent,
    estimatedMonthlyNetIncome: number,
    estimatedMonthlyLivingExpenses: number,
    loanTerm: number,
    repaymentType: RepaymentType
  ) => {
    refetch({
      isFirstHomeBuyer: isFirstHomeBuyer,
      estimatedMonthlyLivingExpenses: estimatedMonthlyLivingExpenses,
      estimatedMonthlyNetIncome: estimatedMonthlyNetIncome,
      loanTerm: loanTerm,
      purchaseIntent: purchaseIntent,
      repaymentType: repaymentType,
    });

    if (isUserSignedIn()) {
      save({
        includeProfile: true,
        includePreference: true,
        profile: {
          estimatedMonthlyNetIncome: estimatedMonthlyNetIncome,
          estimatedMonthlyLivingExpenses: estimatedMonthlyLivingExpenses,
          firstHomeBuyer: isFirstHomeBuyer,
          purchaseIntent: convertPurchaseIntentToPropertyUse(purchaseIntent),
        },
        calcPreferences: {
          loanTerm: loanTerm,
          loanRepaymentsType: repaymentType,
        },
      });
    }
  };

  const handleStateChange = (state: State) => {
    refetch({}, state);
  };

  return {
    handleDepositChange,
    handleInterestRateChange,
    handlePropertyPriceChange,
    handleYourDetailsChange,
    handleStateChange,
  };
};
