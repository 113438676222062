import { useQuery, UseQueryExecute, UseQueryState } from 'urql';

import {
  LoanCalculator,
  LoanCalculatorCalculatorResultsArgs,
} from '../@types/__generated__/graphql';
import { getLoanCalculator } from '../graphql/query/getLoanCalculator';
import { logError } from '../utils/errorHandler';

type LoanCalculatorQueryState = UseQueryState<
  { loanCalculator: LoanCalculator },
  LoanCalculatorCalculatorResultsArgs
>;

type LoanCalculatorQueryResponse = {
  result: LoanCalculatorQueryState;
  reexecuteQuery: UseQueryExecute;
};

export const useQueryCalculator = (
  variables: LoanCalculatorCalculatorResultsArgs
): LoanCalculatorQueryResponse => {
  const [result, reexecuteQuery] = useQuery<
    { loanCalculator: LoanCalculator },
    LoanCalculatorCalculatorResultsArgs
  >({
    query: getLoanCalculator,
    variables,
  });
  if (result.error?.graphQLErrors && result.error.graphQLErrors.length > 1) {
    logError(
      `Error retrieving loan calculator: ${result.error.message}
      ${result.error.graphQLErrors.map((error) => error.toString()).join('\n')}`,
      { component: 'useQueryCalculator' }
    );
  }
  return { result, reexecuteQuery };
};
