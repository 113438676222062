interface LogError {
  (error: string, attributes: object): void;
}

export const logError: LogError = (error, attributes) => {
  if (!(window.newrelic && window.newrelic.noticeError)) {
    return;
  }

  window.newrelic.noticeError(error, attributes);
};
