import { Alert } from '@construct-kit/core';

import { useLoanCalculatorData } from '../../hooks/useLoanCalculatorContext';

export enum AlertType {
  InitialisationError,
  RefetchError,
  HigherDepositWarning,
  NULL,
}

export const AlertBox = () => {
  const { alertType } = useLoanCalculatorData();

  switch (alertType) {
    case AlertType.InitialisationError:
    case AlertType.RefetchError:
      return (
        <Alert variant="error">
          Sorry, we&apos;re unable to calculate your monthly budget right now.
          Try again later.
        </Alert>
      );

    case AlertType.HigherDepositWarning:
      return (
        <Alert variant="warning">
          Your deposit is higher than the property price, so you won&apos;t have
          any repayments. If this seems incorrect, adjust your figures.
        </Alert>
      );
    default:
      return null;
  }
};
