import { DataGetter } from '@argonaut/analytics';

import { EventData } from '../types';

const EVENT_NAME_TOOLTIP_DISPLAYED = 'tooltip_displayed';
const SCHEMA_TOOLTIP_DISPLAYED =
  'iglu:au.com.realestate/tooltip_displayed/jsonschema/1-0-6';

export interface TooltipDisplayedEventData extends EventData {
  schema: string;
  data: {
    event_name: string;
    descriptor: string;
    in_page_source: string;
  };
}

export const getTooltipDisplayedEventDataGetter =
  (descriptor: string, in_page_source: string): DataGetter =>
  (): TooltipDisplayedEventData => ({
    schema: SCHEMA_TOOLTIP_DISPLAYED,
    data: {
      event_name: EVENT_NAME_TOOLTIP_DISPLAYED,
      descriptor,
      in_page_source,
    },
  });
