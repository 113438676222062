import {
  Slider,
  spacingHelper,
  mediaQueryHelper,
  Button,
  Stack,
} from '@construct-kit/core';
import styled from 'styled-components';

export const StyledSlider = styled(Slider)`
  padding: ${spacingHelper('small 0')};
  ${mediaQueryHelper('tablet')} {
    padding: 0;
  }
`;

export const StyledButton = styled(Button)`
  width: max-content;
`;

export const StyledStack = styled(Stack)<{
  isRecalculating: boolean;
}>`
  cursor: ${(props) => (props.isRecalculating ? 'not-allowed' : 'auto')};
  & > div {
    pointer-events: ${(props) => (props.isRecalculating ? 'none' : 'auto')};
  }
`;
