/* eslint-disable @typescript-eslint/restrict-template-expressions */
import { spacingHelper } from '@construct-kit/core';
import styled from 'styled-components';

import { AffordabilityStatus } from '../../@types/__generated__/graphql';

export const Square = styled.div`
  width: ${spacingHelper('small')};
  height: ${spacingHelper('extraSmall')};
`;

const StatusColors = {
  lightestGood: '#65E0DE',
  lightGood: '#2BC5CA',
  good: '#189AA3',
  lightestWarning: '#FFA959',
  lightWarning: '#ED711F',
  warning: '#D15408',
};

export const SquareBar = styled.div<{
  $affordabilityStatus: AffordabilityStatus;
}>`
  display: flex;
  align-items: center;
  gap: 1px;

  ${(props) =>
    props.$affordabilityStatus === AffordabilityStatus.ExceedBudget
      ? `
    & > ${Square}:nth-child(1) {
        background-color: ${StatusColors.lightestWarning};
        border-radius: 2px 0 0 2px;
    }

    & > ${Square}:nth-child(2) {
        background-color: ${StatusColors.lightWarning};
    }

    & > ${Square}:nth-child(3) {
        background-color: ${StatusColors.warning};
        border-radius: 0 2px 2px 0;
    }
    `
      : props.$affordabilityStatus === AffordabilityStatus.WithinBudget
        ? `
    & > ${Square}:nth-child(1) {
        background-color: ${StatusColors.lightestGood};
        border-radius: 2px 0 0 2px;
    }

    & > ${Square}:nth-child(2) {
        background-color: ${StatusColors.lightGood};
    }

    & > ${Square}:nth-child(3) {
        background-color: ${StatusColors.good};
        border-radius: 0 2px 2px 0;
    }
    `
        : ''};
`;
