import { Inline, Skeleton, Strong } from '@construct-kit/core';
import { useMemo, useState } from 'react';

import {
  PrimaryColorText,
  SecondaryColorText,
  emptyGrey,
  expenseBlue,
  remainingTeal,
  repaymentOrange,
} from '../styles';
import {
  LargeLegendText,
  NormalLegendText,
  LegendElementContainer,
  SecondaryTooltip,
  Square,
  StackWithBackground,
} from './styles';
import { BreakdownBar } from './BreakdownBar';
import { errorState } from '../../graphql/errorState';
import { useLoanCalculatorData } from '../../hooks/useLoanCalculatorContext';
import { AlertType } from '../AlertBox';
import { AffordabilityStatus } from '../../@types/__generated__/graphql';
import { AnimationTypeForLegendElement } from './types';
import {
  AnimationState,
  defaultAnimationState,
  getAnimationTypeFromMonetaryValue,
} from './getAnimationTypeFromMonetaryValue';
import { Z_INDEX_HIGH_PRIORITY } from '../../constants';

const LegendElement = ({
  color,
  title,
  formatted,
  large,
  animationType,
  onAnimationEnd,
}: {
  color: string;
  title: string;
  formatted: string;
  large?: boolean;
  animationType: AnimationTypeForLegendElement;
  onAnimationEnd: () => void;
}) => (
  <LegendElementContainer>
    <Inline grow={false} gap="twoExtraSmall">
      <Square color={color} />
      <PrimaryColorText variant="caption">{title}</PrimaryColorText>
    </Inline>
    {large ? (
      <LargeLegendText
        animationType={animationType}
        onAnimationEnd={onAnimationEnd}>
        <Strong>{formatted}</Strong>
      </LargeLegendText>
    ) : (
      <NormalLegendText
        variant="subtitle01"
        animationType={animationType}
        onAnimationEnd={onAnimationEnd}>
        <Strong>{formatted}</Strong>
      </NormalLegendText>
    )}
  </LegendElementContainer>
);

export const Breakdown = () => {
  const {
    loanCalculator,
    fetching,
    error,
    alertType,
    isInitialization,
    previousLoanCalculatorData,
  } = useLoanCalculatorData();

  const monthlyBudget =
    error || !loanCalculator
      ? errorState.calculatorResults.calculatorView.monthlyBudget
      : loanCalculator.calculatorResults.calculatorView.monthlyBudget;

  const previousMonthlyBudget =
    previousLoanCalculatorData?.calculatorResults.calculatorView.monthlyBudget;

  const affordabilityStatus =
    loanCalculator?.calculatorResults.calculatorView.affordabilityStatus ||
    errorState.calculatorResults.calculatorView.affordabilityStatus;

  const tooltips =
    error || !loanCalculator ? errorState.tooltips : loanCalculator.tooltips;

  const isSticky: boolean = affordabilityStatus !== AffordabilityStatus.Default;

  const [animationTypes, setAnimationTypes] = useState<AnimationState>(
    defaultAnimationState
  );

  useMemo(() => {
    if (previousMonthlyBudget && !isInitialization) {
      const previousValues = {
        repayments: previousMonthlyBudget.repayments?.formatted,
        expenses: previousMonthlyBudget.expenses?.formatted,
        remaining: previousMonthlyBudget.remaining?.formatted,
      };

      const currentValues = {
        repayments: monthlyBudget.repayments.formatted,
        expenses: monthlyBudget.expenses.formatted,
        remaining: monthlyBudget.remaining.formatted,
      };

      setAnimationTypes({
        repayments: getAnimationTypeFromMonetaryValue(
          previousValues.repayments,
          currentValues.repayments
        ),
        expenses: getAnimationTypeFromMonetaryValue(
          previousValues.expenses,
          currentValues.expenses
        ),
        remaining: getAnimationTypeFromMonetaryValue(
          previousValues.remaining,
          currentValues.remaining
        ),
      });
    }
  }, [isInitialization, monthlyBudget, previousMonthlyBudget]);

  return (
    <StackWithBackground $sticky={isSticky}>
      <Inline grow={false} gap="twoExtraSmall">
        <SecondaryColorText variant="body02">
          Your monthly budget
        </SecondaryColorText>
        <SecondaryTooltip
          sizeVariant="small"
          disabled={fetching || alertType === AlertType.InitialisationError}
          zIndex={Z_INDEX_HIGH_PRIORITY}>
          {tooltips.monthlyBudget.content}
        </SecondaryTooltip>
      </Inline>
      <Skeleton loading={fetching && isInitialization} width="100%">
        <BreakdownBar
          repayments={monthlyBudget.repayments}
          expenses={monthlyBudget.expenses}
          remaining={monthlyBudget.remaining}
        />
        <Inline
          grow={false}
          justifyContent="space-between"
          gap="0"
          alignItems="flex-start">
          <LegendElement
            color={
              monthlyBudget.repayments.filled ? repaymentOrange : emptyGrey
            }
            title="Repayments"
            formatted={monthlyBudget.repayments.formatted}
            large={true}
            animationType={animationTypes.repayments}
            onAnimationEnd={() => setAnimationTypes(defaultAnimationState)}
          />
          <LegendElement
            color={monthlyBudget.expenses.filled ? expenseBlue : emptyGrey}
            title="Expenses"
            formatted={monthlyBudget.expenses.formatted}
            animationType={animationTypes.expenses}
            onAnimationEnd={() => setAnimationTypes(defaultAnimationState)}
          />
          <LegendElement
            color={monthlyBudget.remaining.filled ? remainingTeal : emptyGrey}
            title="Remaining"
            formatted={monthlyBudget.remaining.formatted}
            animationType={animationTypes.remaining}
            onAnimationEnd={() => setAnimationTypes(defaultAnimationState)}
          />
        </Inline>
      </Skeleton>
    </StackWithBackground>
  );
};
