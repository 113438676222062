import { gql } from 'urql/core';

export const CalculatorResultsFragment = gql/* GraphQL */ `
  fragment CalculatorResultsFragment on LoanCalculatorResults {
    launcherView {
      title {
        text
      }
      description
      affordabilityStatus
      repayments {
        formatted
        label
        unit
      }
    }
    calculatorView {
      affordabilityStatus
      higherDepositWarning {
        message
      }
      monthlyBudget {
        repayments {
          formatted
          ratio
          filled
        }
        expenses {
          formatted
          ratio
          filled
        }
        remaining {
          formatted
          ratio
          filled
        }
      }
      estimatedPropertyPrice {
        formattedAvailableDeposit
        formattedLmiEstimate
        formattedLoanAmount
        amount {
          value
          formatted
          slider {
            minValue
            maxValue
            stepIncrement
          }
        }
      }
      deposit {
        description
        amount {
          value
          formatted
          slider {
            minValue
            maxValue
            stepIncrement
          }
        }
        upfrontCosts {
          stampDuty
          transferFee
          governmentFees
        }
        availableDeposit {
          availableAmount
          formattedRatio
        }
      }
      interestRate {
        amount {
          value
          formatted
          slider {
            minValue
            maxValue
            stepIncrement
          }
        }
      }
      nextSteps {
        affordabilityStatus
      }
      yourDetails {
        estimatedMonthlyNetIncome
        estimatedMonthlyLivingExpenses
        preferredLoanDetails {
          repaymentType
          loanTerm
        }
        aboutYou {
          purchaseIntent
          firstHomeBuyer
        }
      }
    }
    detailsModal {
      estimatedMonthlyNetIncome
      estimatedMonthlyLivingExpenses
      preferredLoanDetails {
        repaymentType
        loanTerm
        loanTermSlider {
          minValue
          maxValue
          stepIncrement
        }
      }
      aboutYou {
        purchaseIntent
        isFirstHomeBuyer
      }
    }
  }
`;
