import {
  Button,
  Dialog,
  mediaQueryHelper,
  paletteHelper,
  typographyHelper,
} from '@construct-kit/core';
import styled from 'styled-components';

export const MobileCTAButton = styled(Button).attrs({
  variant: 'naked',
  sizeVariant: 'medium',
  compact: true,
})`
  // This hack makes circular edges
  border-radius: 9999px;
  background-color: ${paletteHelper('backgroundSecondaryBase')};
  width: fit-content;

  ${mediaQueryHelper('medium')} {
    display: none;
  }

  ${typographyHelper('body02')}
`;

export const HeaderExpandedDialog = styled(Dialog)`
  .calcModalHeader {
    padding: 0.75rem 3rem 0.75rem 0.75rem;
    height: fit-content;
  }
`;
