export const MCLogo = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg">
      <rect width="24" height="24" rx="12" fill="#006875" />
      <g clipPath="url(#clip0_6465_6335)">
        <path
          d="M11.6592 10.0327C11.6592 10.0327 14.9886 5.39668 18.5047 5.39668C19.6713 5.39668 20.469 5.94939 20.5463 6.72084C20.6309 7.51418 20.1642 8.50001 18.5834 8.39647C17.4736 8.32501 13.6105 7.68043 11.1605 12.1633C11.1605 12.1633 10.9942 9.24667 9.47321 7.53459C8.66675 6.62605 8.74404 5.78459 9.55633 5.28584C9.55633 5.28584 10.9097 4.59751 11.3282 6.67126C11.5163 7.60022 11.8036 9.06147 11.6592 10.0327Z"
          fill="#5AE2E0"
        />
        <path
          d="M10.7351 14.0416C10.7351 14.0416 12.9226 8.97246 18.998 9.78912C20.0189 9.92621 20.6839 10.6233 20.592 11.6412C20.4885 12.7889 19.1745 13.0529 18.0457 12.7437C14.2264 11.6966 12.6324 12.1735 10.4143 15.4562C9.69388 11.4166 7.14326 10.8245 6.34118 9.59954C6.08305 9.20725 5.93284 8.481 6.38784 8.04204C6.6021 7.82365 6.88888 7.69121 7.19407 7.66972C7.49925 7.64823 7.80176 7.73917 8.04451 7.92538C9.08138 8.74204 10.5587 12.1356 10.7351 14.0416Z"
          fill="#E21E2D"
        />
        <path
          d="M9.75062 18.8906C9.75062 18.8906 9.92125 14.7956 4.62896 13.1214C3.80063 12.856 3.29167 11.8673 3.65334 11.1789C4.00042 10.5183 4.96584 10.3914 5.85542 10.8377C9.00542 12.4229 10.0642 16.8912 10.0642 16.8912C12.711 12.9712 16.8381 13.4831 18.6523 13.9498C20.5117 14.4266 20.7975 15.637 20.4883 16.4595C20.1967 17.2529 19.1598 17.756 17.3223 16.8198C17.1589 16.7483 12.1729 14.0562 9.75062 18.8906Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_6465_6335">
          <rect
            width="17.2083"
            height="14"
            fill="white"
            transform="translate(3.39453 5)"
          />
        </clipPath>
      </defs>
    </svg>
  );
};
