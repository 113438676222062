import {
  spacingHelper,
  Stack,
  paletteHelper,
  mediaQueryHelper,
  Tooltip,
  typographyHelper,
} from '@construct-kit/core';
import styled, { css, keyframes } from 'styled-components';

import { PrimaryColorText } from '../styles';
import { AnimationTypeForLegendElement } from './types';

export const Square = styled.div<{ color: string }>`
  width: ${spacingHelper('small')};
  height: ${spacingHelper('small')};
  border-radius: 2px;
  background-color: ${(props) => props.color};
`;

export const StackWithBackground = styled(Stack).attrs({
  gap: 'extraSmall',
})<{ $sticky: boolean }>`
  background-color: ${paletteHelper('backgroundSecondaryBase')};
  padding: ${spacingHelper('medium')};
  border-radius: 0;

  ${mediaQueryHelper('tablet')} {
    border-radius: ${({ theme }) => theme.border.radius.large};
  }

  ${mediaQueryHelper('medium')} {
    position: auto;
    z-index: auto;
    top: auto;
  }

  ${(props) =>
    props.$sticky &&
    css`
      position: sticky;
      z-index: 99;
      top: 0;
    `}
`;

export const SecondaryTooltip = styled(Tooltip)<{ disabled: boolean }>`
  * > svg {
    color: ${(props) =>
      props.disabled
        ? paletteHelper('textDisabled')
        : paletteHelper('textSecondary')};
  }
`;

export const LegendElementContainer = styled.div`
  min-width: 80px;

  ${mediaQueryHelper('medium')} {
    min-width: 110px;
  }
`;

const moveInFromTop = keyframes`
  0% {
    opacity: 0;
    transform: translateY(-100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const moveInFromBottom = keyframes`
  0% {
    opacity: 0;
    transform: translateY(100%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const NormalLegendText = styled(PrimaryColorText)<{
  animationType: AnimationTypeForLegendElement;
}>`
  ${(props) => getAnimation(props.animationType)}
`;

export const LargeLegendText = styled(NormalLegendText)<{
  animationType: AnimationTypeForLegendElement;
}>`
  ${typographyHelper('title01')}

  ${mediaQueryHelper('tablet')} {
    ${typographyHelper('largeTitle')}
  }
`;

const getAnimation = (animationType: AnimationTypeForLegendElement) => {
  switch (animationType) {
    case AnimationTypeForLegendElement.MOVE_IN_FROM_TOP:
      return css`
        animation: ${moveInFromTop} 0.2s ease forwards;
      `;
    case AnimationTypeForLegendElement.MOVE_IN_FROM_BOTTOM:
      return css`
        animation: ${moveInFromBottom} 0.2s ease forwards;
      `;
    default:
      return '';
  }
};
