import { request, withTransactionId } from '@rea-group/fs-web-utilities';
import { SubmitFormData } from '@rea-group/fs-quick-lead-components/lib/types/components/forms/QuickLeads/types';

import { COMPANION_BASE_URL, REA_BASE_URL } from '../environment';

export const submitEnquiryForm = (
  formData: SubmitFormData,
  eventSource: string
) => {
  return request.fetchWithAuth(
    REA_BASE_URL,
    generateRACBFormEndpoint(eventSource),
    {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include',
      body: JSON.stringify(formData),
    },
    withTransactionId
  );
};

export const generateRACBFormEndpoint = (source: string): string =>
  `${COMPANION_BASE_URL}/enquiry/contact/racb?source=${source}&org=REAHL`;
