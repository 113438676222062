import {
  Card,
  Dialog,
  Divider,
  Spinner,
  Stack,
  spacingHelper,
  mediaQueryHelper,
} from '@construct-kit/core';
import styled from 'styled-components';

export const YourDetailsDialog = styled(Dialog)`
  .detailsModalHeader {
    padding: ${spacingHelper('extraSmall extraSmall extraSmall medium')};
    width: 100%;

    ${mediaQueryHelper('tablet')} {
      width: 400px;
    }
  }
`;

export const StyledBody = styled(Stack).attrs({
  gap: 'large',
})`
  padding: ${spacingHelper('large medium')};
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
`;

export const OverlaySpinner = styled(Spinner)`
  position: absolute;
  top: calc(50% - 2rem);
  left: calc(50% - 2rem);
`;

export const SpinnerContainer = styled(Card)`
  height: 915px;
  min-width: 348px;
  width: 100%;
  position: relative;
  border: none;
  box-shadow: none;
`;
