import { H2, Modal, Stack, Strong, Text } from '@construct-kit/core';
import { useState } from 'react';
import { trackEvent as trackEventFun } from '@argonaut/analytics';

import { CalcAssumptionsDialog, StyledButton } from './styles';
import { getTooltipDisplayedEventDataGetter } from '../../tracking/eventData/tooltipDisplayed';
import { TrackEventProps } from '../../tracking/types';
import { useLoanCalculatorData } from '../../hooks/useLoanCalculatorContext';

const descriptorForCalcAssum = 'homeloan_calculator_assumptions';
const inPageSourceForCalcAssum = 'buy_pdp';

export const CalculatorAssumptions = ({
  trackEvent = trackEventFun,
}: TrackEventProps) => {
  const { fetching } = useLoanCalculatorData();

  const [isCalcAssumptionsOpen, setIsCalcAssumptionsOpen] = useState(false);

  const handleCalcAssumptionsOpen = () => {
    setIsCalcAssumptionsOpen(true);
    trackEvent(
      getTooltipDisplayedEventDataGetter(
        descriptorForCalcAssum,
        inPageSourceForCalcAssum
      )
    );
  };

  return (
    <>
      <StyledButton onClick={handleCalcAssumptionsOpen} disabled={fetching}>
        View calculator assumptions
      </StyledButton>
      <Modal
        opened={isCalcAssumptionsOpen}
        onRequestClose={() => setIsCalcAssumptionsOpen(false)}
        getAppElement={() => document.getElementById('body')!}>
        <CalcAssumptionsDialog
          maxWidth="550px"
          autoFullScreen={true}
          onClose={() => setIsCalcAssumptionsOpen(false)}
          dangerouslySetClassNames={{
            header: 'calcAssumptionsHeader',
          }}
          header={<H2>Calculator assumptions</H2>}>
          <Stack inset="large medium">
            <Text>
              The purpose of this calculator is to assist you in estimating your
              loan amount, the upfront costs associated with your loan and the
              repayments and whether you may be able to afford the property.
              These estimates are based on information entered by you and
              information sourced from third parties, and does not take into
              account loan eligibility criteria or your complete financial
              position.
            </Text>
            <Text>
              Your estimated repayments, expenses, amount remaining and the
              other information presented here is provided as a guide only. It
              should not be relied upon for the purposes of entering into any
              legal or financial commitments. Realestate.com.au Pty Ltd makes no
              warranty or representation as to the accuracy or reliability of
              the information provided.
            </Text>
            <Text>
              We have made a number of assumptions when producing the
              calculations, which affect the results of our calculations. Our
              main assumptions are set out below:
            </Text>
            <Stack gap="extraSmall">
              <Strong>Interest rate and repayments</Strong>
              <Text>
                We have assumed that the initial repayment amount is payable for
                the entire loan term, however repayment amounts will likely
                change for a variety of reasons, including to reflect interest
                rate changes and changes to your repayment type. Your annual
                interest charge is divided equally over 12 monthly payments (in
                reality, interest is calculated daily and charged monthly, which
                can lead to your interest charge varying between months).
                Interest is charged to the loan account at the same frequency
                and on the same day as the repayments are made (this may
                ultimately not be the case for your loan).
              </Text>
            </Stack>
            <Stack gap="extraSmall">
              <Strong>Upfront cost</Strong>
              <Text>
                The calculations include government fees such as land
                registration costs and other administrative costs, stamp duty on
                the transfer of land for the purchase price entered and on one
                real property mortgage, as well as a title transfer fee. The
                calculations don&apos;t include the registration costs for
                discharges of mortgage or for additional mortgages. Stamp duty
                and registration costs have been calculated using the rates from
                the relevant government authority websites and do not take into
                account all concessions you may be eligible for (such as first
                home buyers grants) or any surcharges or additional duties that
                may apply given your individual circumstances. We cannot
                guarantee that these rates are correct, up to date or are the
                ones which would apply to you. You should confirm the government
                costs and duties payable with the relevant government
                authorities. We have not been able to include all the possible
                set up costs that you may have to pay such as lender&apos;s
                fees, every government fee, conveyancing costs and moving costs,
                as these change based on individual circumstances. Any estimate
                of Lenders Mortgage Insurance can change in the future.
              </Text>
            </Stack>
          </Stack>
        </CalcAssumptionsDialog>
      </Modal>
    </>
  );
};
