/* eslint-disable import/no-named-as-default-member */
/* eslint-disable @typescript-eslint/ban-types */
/* eslint-disable @typescript-eslint/no-explicit-any */

// Copied from CK https://git.realestate.com.au/construct-kit/construct-kit-web/blob/dev/src/lib/private/forwardRef/forwardRef.types.ts

import React from 'react';

type KnownTarget<Props = any> =
  | keyof JSX.IntrinsicElements
  | React.ElementType<Props>;

export type As<Props = any> = KnownTarget<Props>;

type FastOmit<T extends object, U extends string | number | symbol> = {
  [K in keyof T as K extends U ? never : K]: T[K];
};

export type Substitute<A extends object, B extends object> = FastOmit<
  A,
  keyof B
> &
  B;

// Without this, Typescript fails to infer the event handler types on components correctly
type NoInfer<T> = [T][T extends any ? 0 : never];

type PolymorphicProps<
  CustomProps extends object,
  BaseComponent extends keyof JSX.IntrinsicElements,
  AsComponent extends As,
  BaseProps extends object = JSX.IntrinsicElements[BaseComponent],
  AsComponentProps extends object = React.ComponentPropsWithRef<AsComponent>,
> = NoInfer<
  FastOmit<
    Substitute<Substitute<BaseProps, AsComponentProps>, CustomProps>,
    'as'
  >
> & { as?: AsComponent };

export interface ComponentWithAs<
  Component extends keyof JSX.IntrinsicElements,
  Props extends object = {},
> extends React.ForwardRefExoticComponent<
    Substitute<JSX.IntrinsicElements[Component], Props>
  > {
  <AsComponent extends As = Component>(
    props: PolymorphicProps<Props, Component, AsComponent>
  ): JSX.Element;
}

// This gets the HTML Element version of the JSX element (e.g. "button" becomes HTMLButtonElement)
type ElementDetails<Component extends keyof JSX.IntrinsicElements> =
  JSX.IntrinsicElements[Component] extends React.DetailedHTMLProps<
    infer A,
    infer T
  >
    ? { type: T; attributes: A }
    : { type: SVGSVGElement };

const forwardRef = <
  Props extends object,
  Component extends keyof JSX.IntrinsicElements,
>(
  component: React.ForwardRefRenderFunction<
    ElementDetails<Component>['type'],
    Substitute<
      React.ComponentPropsWithoutRef<Component>,
      Props & {
        as?: As;
      }
    >
  >
) => {
  return React.forwardRef(component) as ComponentWithAs<Component, Props>;
};

export default forwardRef;
