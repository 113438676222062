import { Inline } from '@construct-kit/core';

import {
  emptyGrey,
  expenseBlue,
  remainingTeal,
  repaymentOrange,
} from '../../styles';
import { Bar } from './styles';
import { IndicatorValue } from '../../../@types/__generated__/graphql';

interface BarItem {
  ratio: number;
  color: string;
}

const createBar = (ratio: number, filled: boolean, color: string): BarItem[] =>
  ratio === 0 ? [] : [{ ratio, color: filled ? color : emptyGrey }];

export const BreakdownBar = ({
  repayments,
  expenses,
  remaining,
}: {
  repayments: Omit<IndicatorValue, 'formatted'>;
  expenses: Omit<IndicatorValue, 'formatted'>;
  remaining: Omit<IndicatorValue, 'formatted'>;
}) => {
  const repaymentsBar = createBar(
    repayments.ratio,
    repayments.filled,
    repaymentOrange
  );
  const expensesBar = createBar(expenses.ratio, expenses.filled, expenseBlue);
  const remainingBar = createBar(
    remaining.ratio,
    remaining.filled,
    remainingTeal
  );

  const bars: BarItem[] = [...repaymentsBar, ...expensesBar, ...remainingBar];

  return (
    <Inline gap="0" grow={true}>
      {bars.map((bar, i) => (
        <Bar
          key={i}
          data-testid="bar"
          $isLast={bars.length - 1 === i}
          $isFirst={i === 0}
          $color={bar.color}
          $ratio={bar.ratio}
        />
      ))}
    </Inline>
  );
};
