import { spacingHelper } from '@construct-kit/core';
import styled from 'styled-components';

export const Bar = styled.div<{
  $isLast: boolean;
  $isFirst: boolean;
  $color: string;
  $ratio: number;
}>`
  height: ${spacingHelper('small')};
  background-color: ${(props) => props.$color};
  flex-grow: ${(props) => props.$ratio};

  ${({ $isLast, $isFirst }) => {
    if ($isLast && $isFirst) {
      return `border-radius: 6px;`;
    } else if ($isFirst) {
      return `border-radius: 6px 0 0 6px;
        margin-right: 2px !important;`;
    } else if ($isLast) {
      return `border-radius: 0 6px 6px 0;`;
    }
    return 'margin-right: 2px !important;';
  }}

  transition: flex-grow 0.75s cubic-bezier(0.75, 1.5, 0.25, 1);
`;

// Explanation of cubic-bezier values:
// cubic-bezier(0.75, 2, 0.25, 1)

/*
 * 0.75 – x1: Three-quarters of the way through the duration of the animation.
 *        This means the first part of the animation will accelerate quickly.
 * 2 – y1: The animation overshoots by 2 times the final value at this point.
 *        This substantial overshoot creates a pronounced bounce effect.
 * 0.25 – x2: Again, three-quarters of the way through the duration of the animation.
 *        This ensures symmetry in the timing curve.
 * 1 – y2: The animation returns to its final value by the end of the duration.
 *        This ensures the animation settles correctly at its target value.
 */
