import React from 'react';
import styled from 'styled-components';
import { Text, spacingHelper } from '@construct-kit/core';

import { TableItem } from './DetailsTable';

const StyledItem = styled.div`
  display: flex;
  justify-content: space-between;
  padding: ${spacingHelper('extraSmall medium')};
`;

const Line = ({ title, value }: TableItem) => (
  <>
    <StyledItem>
      <Text variant="body02">{title}</Text>
      <Text variant="subtitle02">{value}</Text>
    </StyledItem>
  </>
);

export default Line;
