import {
  Card,
  paletteHelper,
  spacingHelper,
  mediaQueryHelper,
  Inline,
} from '@construct-kit/core';
import styled from 'styled-components';

export const StyledCard = styled(Card)`
  background-color: ${paletteHelper('backgroundSecondaryBase')};
  padding: ${spacingHelper('medium')};
  display: flex;
  flex-direction: column;
  gap: ${spacingHelper('medium')};
  box-shadow: none;

  ${mediaQueryHelper('large')} {
    flex-direction: row;
    align-items: center;
  }
  justify-content: space-between;
`;

export const CtaInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingHelper('extraSmall')};
`;

export const StyledInline = styled(Inline).attrs({
  gap: '0.25rem',
  grow: false,
})`
  color: ${paletteHelper('textSuccess')};
  h4 {
    color: ${paletteHelper('textSuccess')};
  }
`;
