import { gql } from 'urql/core';

export const saveCalculatorProfiles = gql/* GraphQL */ `
  mutation SaveCalculatorProfiles(
    $profile: ProfileInput!
    $includeProfile: Boolean!
    $calcPreferences: MortgageCalcPreferencesInput!
    $includePreference: Boolean!
    $listingId: String
    $purchasePrice: Float
    $includePurchasePrice: Boolean!
  ) {
    saveProfile(input: $profile) @include(if: $includeProfile) {
      personal {
        firstHomeBuyer
        purchaseIntent
      }
      financial {
        totalSavings
        estimatedMonthlyNetIncome
        estimatedMonthlyLivingExpenses
      }
    }
    saveMortgageCalcPreferences(input: $calcPreferences)
      @include(if: $includePreference) {
      interestRate
      loanTerm
      loanRepaymentsType
    }
    savePurchasePrice(listingId: $listingId, purchasePrice: $purchasePrice)
      @include(if: $includePurchasePrice) {
      plannedPurchasePrice
    }
  }
`;
