import { BuyerReadiness } from '@rea-group/fs-tracking';

export type TextInputProps = {
  label: string | React.ReactNode;
  id: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
  onBlur?: () => void;
  isValid?: boolean;
  errorMessage?: string;
  type?: string;
  autoValidate?: boolean;
  maxLength?: number;
  disabled?: boolean;
  hideLabel?: boolean;
  sizeVariant?: string;
  showError?: boolean;
};

export enum SubmitStatus {
  ERROR = 'ERROR',
  SUCCESS = 'SUCCESS',
  UNSENT = 'UNSENT',
  SENDING = 'SENDING',
}

export type SubmitFormData = {
  enquiryType: string;
  stream: string;
  phone: string;
  fullName: string;
  buyerReadiness?: BuyerReadiness | null;
  state?: string;
  includeFinancialData?: boolean;
};

/* eslint-disable camelcase */
export type ContactFormSubmittedEvent = {
  schema: string;
  data: {
    event_name: string;
    form_name: string;
    enquiry_type?: string;
    fulfilment_channel?: string;
  };
};
/* eslint-disable camelcase */

export type QuickLeadConsumingApp = 'Broker Carousel' | 'Other';
