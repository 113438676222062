import { useState } from 'react';
import { Stack } from '@construct-kit/core';

import { Breakdown } from '../Breakdown';
import { NextStepCta } from '../NextStepCta';
import { InterestRate } from '../InterestRate';
import { CalculatorAssumptions } from '../CalculatorAssumptions';
import { YourDetails } from '../YourDetails';
import {
  Content,
  SlidersSection,
  YourDetailsSection,
  ReactiveArea,
} from './styles';
import { EstimatedPropertyPriceSection } from '../EstimatedPropertyPriceSection';
import { DepositSection } from '../DepositSection';
import { PoweredByMC } from '../PoweredByMC';
import { AlertBox } from '../AlertBox';
import { useLoanCalculatorData } from '../../hooks/useLoanCalculatorContext';
import { AffordabilityStatus } from '../../@types/__generated__/graphql';
import { YourDetailsModal } from '../YourDetailsModal';

export const Calculator = () => {
  const { loanCalculator } = useLoanCalculatorData();
  const [isYourDetailsModalOpen, setIsYourDetailsModalOpen] = useState(false);

  const affordabilityStatus =
    loanCalculator?.calculatorResults.calculatorView.affordabilityStatus ||
    AffordabilityStatus.Default;
  return (
    <Stack id="calculator">
      <Breakdown />
      <Content>
        <AlertBox />
        {affordabilityStatus === AffordabilityStatus.Default && (
          <NextStepCta setIsYourDetailsModalOpen={setIsYourDetailsModalOpen} />
        )}
        <ReactiveArea>
          <SlidersSection>
            <EstimatedPropertyPriceSection />
            <DepositSection />
            <InterestRate />
          </SlidersSection>
          <YourDetailsSection>
            <YourDetails
              setIsYourDetailsModalOpen={setIsYourDetailsModalOpen}
            />
            <CalculatorAssumptions />
          </YourDetailsSection>
        </ReactiveArea>
        {affordabilityStatus !== AffordabilityStatus.Default && (
          <NextStepCta setIsYourDetailsModalOpen={setIsYourDetailsModalOpen} />
        )}
        <PoweredByMC />
      </Content>
      <YourDetailsModal
        isOpen={isYourDetailsModalOpen}
        closeModal={() => setIsYourDetailsModalOpen(false)}
      />
    </Stack>
  );
};
