import { useEffect, useState } from 'react';
import { trackEvent as trackEventFn } from '@argonaut/analytics';

import { LoanCalculator } from '../../@types/__generated__/graphql';
import { errorState } from '../../graphql/errorState';
import { useLoanCalculatorData } from '../../hooks/useLoanCalculatorContext';
import { useLoanCalculatorEventHandlers } from '../../hooks/useLoanCalculatorEventHandlers';
import { AlertType } from '../AlertBox';
import { SliderQuestion, SliderQuestionType } from '../SliderQuestion';
import { TrackEventProps } from '../../tracking/types';
import { getCalculatorInteractionEventDataGetter } from '../../tracking/eventData/calculatorInteraction';

const userActionForReset = 'reset_interest_rate_to_default';
const fieldUpdated: string = 'home-loan-calculator:interestRate';

export const InterestRate = ({
  trackEvent = trackEventFn,
}: TrackEventProps) => {
  const {
    loanCalculator,
    fetching,
    error,
    refetch,
    alertType,
    isInitialization,
  } = useLoanCalculatorData();

  const { handleInterestRateChange } = useLoanCalculatorEventHandlers({
    refetch,
  });

  const [loanCalculatorData, setLoanCalculatorData] = useState<LoanCalculator>(
    error || !loanCalculator ? errorState : loanCalculator
  );

  useEffect(() => {
    if (loanCalculator && AlertType.RefetchError !== alertType) {
      setLoanCalculatorData(loanCalculator);
    }
  }, [alertType, loanCalculator]);

  const handleValueChange = (value: number, userAction: string) => {
    if (
      value ==
      loanCalculatorData.calculatorResults.calculatorView.interestRate.amount
        .value
    ) {
      return;
    }
    setLoanCalculatorData((prev) => ({
      ...prev,
      calculatorResults: {
        ...prev.calculatorResults,
        calculatorView: {
          ...prev.calculatorResults.calculatorView,
          interestRate: {
            ...prev.calculatorResults.calculatorView.interestRate,
            amount: {
              ...prev.calculatorResults.calculatorView.interestRate.amount,
              value,
            },
          },
        },
      },
    }));
    handleInterestRateChange(value);

    trackEvent(
      getCalculatorInteractionEventDataGetter(userAction, fieldUpdated)
    );
  };

  const resetToDefaultInterestRate = () => {
    // https://git.realestate.com.au/financial-experiences/loan-repayment-calculator-api/blob/main/docs/adrs/04-reset-interest-rate.md
    handleInterestRateChange(0);
    trackEvent(
      getCalculatorInteractionEventDataGetter(userActionForReset, fieldUpdated)
    );
  };

  const interestRate =
    loanCalculatorData.calculatorResults.calculatorView.interestRate;

  return (
    <SliderQuestion
      fetching={fetching}
      title="Interest rate"
      value={interestRate.amount.value}
      titleTooltip={loanCalculatorData.tooltips.interestRate.content}
      sliderValues={interestRate.amount.slider}
      sliderType={SliderQuestionType.PERCENT}
      refetchFunction={handleValueChange}
      sliderCTA={{
        modalCTAText: 'Reset to default',
        onCTAClick: resetToDefaultInterestRate,
      }}
      disabled={alertType === AlertType.InitialisationError}
      isInitialization={isInitialization}
    />
  );
};
