import { CommonQuickLeadsModal } from '@rea-group/fs-quick-lead-components';
import { trackEvent as trackEventFun } from '@argonaut/analytics';

import { leadSourceForGotQuestions } from '../../constants';
import { getContactFormSubmittedEventDataGetter } from '../../tracking/eventData/formSubmitted';
import { submitEnquiryForm } from '../../utils/handleRequest';
import { TrackEventProps } from '../../tracking/types';
import { useLoanCalculatorData } from '../../hooks/useLoanCalculatorContext';

export const formNameForGotQuestions = 'got_questions';

export const GotQuestionsModal = ({
  trackEvent = trackEventFun,
}: TrackEventProps) => {
  const {
    isContactFormModalOpen,
    setIsCalculatorModalOpen,
    setIsContactFormModalOpen,
  } = useLoanCalculatorData();

  const closeModal = () => {
    setIsCalculatorModalOpen(true);
    setIsContactFormModalOpen(false);
  };

  return (
    <CommonQuickLeadsModal
      isModalOpen={isContactFormModalOpen}
      onClose={closeModal}
      eventSource={leadSourceForGotQuestions}
      trackEvent={() => {
        trackEvent(
          getContactFormSubmittedEventDataGetter(formNameForGotQuestions)
        );
      }}
      uiTextProps={{
        dialogHeaderText: 'Get in touch',
        contentHeaderText:
          'Our Home Loan Concierge team is here to help you find the right broker.',
        buttonText: 'Send',
      }}
      fireRequest={submitEnquiryForm}
    />
  );
};
