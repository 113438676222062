import { Button, Inline, Skeleton, Stack } from '@construct-kit/core';
import { trackEvent as trackEventFun } from '@argonaut/analytics';

import {
  ContentContainer,
  GridCTA,
  GridIndicator,
  GridSubtext,
  GridTitle,
} from './styles';
import { PrimaryColorText, SecondaryColorText } from '../styles';
import { StatusIndicator } from '../StatusIndicator';
import { errorState } from '../../graphql/errorState';
import { useLoanCalculatorData } from '../../hooks/useLoanCalculatorContext';
import { getCalculatorInteractionEventDataGetter } from '../../tracking/eventData/calculatorInteraction';
import { TrackEventProps } from '../../tracking/types';

const userActionForCalculatorInteraction = 'launcher_CTA_Calculator';

export type LauncherProps = {
  onCalculateClickForLargeScreen: () => void;
} & TrackEventProps;

export const Launcher = ({
  onCalculateClickForLargeScreen,
  trackEvent = trackEventFun,
}: LauncherProps) => {
  const {
    loanCalculator,
    isLargeScreen,
    setIsCalculatorModalOpen,
    fetching,
    error,
  } = useLoanCalculatorData();

  const launcherView =
    error || !loanCalculator
      ? errorState.calculatorResults.launcherView
      : loanCalculator.calculatorResults.launcherView;

  const onCalculateClick = () => {
    if (isLargeScreen) {
      onCalculateClickForLargeScreen?.();
    } else {
      setIsCalculatorModalOpen(true);
    }
    trackEvent(
      getCalculatorInteractionEventDataGetter(
        userActionForCalculatorInteraction
      )
    );
  };

  return (
    <>
      <ContentContainer $hasSubtext={!!launcherView.description}>
        <GridTitle>
          <Skeleton loading={fetching}>
            <PrimaryColorText variant="subtitle01">
              {launcherView.title.text}
            </PrimaryColorText>
          </Skeleton>
        </GridTitle>
        {/* We should never receive both description and repayments.formatted from the API, the states are mutually exclusive */}
        {launcherView.description && (
          <GridSubtext>
            <Inline>
              <Skeleton loading={fetching}>
                <SecondaryColorText variant="body02">
                  {launcherView.description}
                </SecondaryColorText>
              </Skeleton>
            </Inline>
          </GridSubtext>
        )}
        {launcherView.repayments?.formatted && (
          <GridIndicator>
            <Inline gap="small" style={{ marginRight: 'auto' }}>
              <StatusIndicator
                affordabilityStatus={launcherView.affordabilityStatus}
              />
              <Stack justifyContent="start" gap="0">
                <SecondaryColorText variant="caption">
                  Monthly repayments
                </SecondaryColorText>
                <PrimaryColorText variant="title02">
                  {launcherView.repayments.formatted}
                </PrimaryColorText>
              </Stack>
            </Inline>
          </GridIndicator>
        )}
        <GridCTA>
          <Button
            fullWidth={false}
            variant="outline"
            sizeVariant="small"
            onClick={onCalculateClick}>
            Calculate
          </Button>
        </GridCTA>
      </ContentContainer>
    </>
  );
};
