import {
  Card,
  Divider,
  H1,
  Inline,
  paletteHelper,
  spacingHelper,
  Text,
} from '@construct-kit/core';
import styled from 'styled-components';

export const StyledDivider = styled(Divider)`
  margin: 0;
`;

export const StyledInline = styled(Inline)`
  padding: ${spacingHelper('small')};
`;

export const ValueText = styled(Text)`
  text-align: end;
  color: ${paletteHelper('textPrimary')};
`;

export const LabelText = styled(Text)`
  text-align: start;
  color: ${paletteHelper('textSecondary')};
`;

export const HeaderText = styled(H1)`
  color: ${paletteHelper('textPrimary')};
`;

export const StyledCard = styled(Card)`
  box-shadow: none;
  border: 1px solid ${paletteHelper('borderSecondary')};
`;
