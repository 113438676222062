import { Provider } from 'urql';

import { Calculator } from '../components/Calculator';
import { Launcher, LauncherProps } from '../components/Launcher';
import { client } from '../graphql/client';
import {
  LoanCalculatorProvider,
  LoanCalculatorProviderProps,
} from '../hooks/useLoanCalculatorContext';
import ErrorBoundary from '../components/ErrorBoundary';
import { CalculatorModal } from '../components/CalculatorModal';
import { GotQuestionsModal } from '../components/GotQuestionsModal';

export const LOAN_CALCULATOR_COMPONENT_NAME = 'loan-calculator-components';

const NexusComponent = () => {
  return (
    <ErrorBoundary component={LOAN_CALCULATOR_COMPONENT_NAME}>
      <Calculator />
    </ErrorBoundary>
  );
};

const NexusLauncher = (props: LauncherProps) => {
  return (
    <ErrorBoundary component="loan-calculator-components-launcher">
      <Launcher {...props} />
    </ErrorBoundary>
  );
};

const NexusCalculatorModal = () => {
  return (
    <ErrorBoundary component="loan-calculator-components-calculator-modal">
      <CalculatorModal />
    </ErrorBoundary>
  );
};

const NexusContactModal = () => {
  return (
    <ErrorBoundary component="loan-calculator-components-contact-modal">
      <GotQuestionsModal />
    </ErrorBoundary>
  );
};

const LoanCalculatorComponentsProvider = ({
  listingDetails,
  propertyDetails,
  isLargeScreen,
  children,
}: LoanCalculatorProviderProps) => {
  return (
    <Provider value={client}>
      <LoanCalculatorProvider
        listingDetails={listingDetails}
        propertyDetails={propertyDetails}
        isLargeScreen={isLargeScreen}>
        {children}
      </LoanCalculatorProvider>
    </Provider>
  );
};

export {
  NexusComponent as Calculator,
  NexusLauncher as Launcher,
  NexusCalculatorModal as CalculatorModal,
  NexusContactModal as ContactModal,
  LoanCalculatorComponentsProvider,
};
