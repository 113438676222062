import { ButtonGroup, Link, paletteHelper, Text } from '@construct-kit/core';
import styled from 'styled-components';

// We need to override the stylesheets from RUI in the Content Hub
export const PrimaryColorText = styled(Text)<{ disabled?: boolean }>`
  color: ${paletteHelper('textPrimary')};
  color: ${(props) =>
    props.disabled
      ? paletteHelper('textDisabled')
      : paletteHelper('textPrimary')};
`;

export const SecondaryColorText = styled(Text)`
  color: ${paletteHelper('textPrimary')};
`;

// Colors used for breakdown component
export const repaymentOrange = '#ED711F';
export const remainingTeal = '#0C747D';
export const expenseBlue = '#0078BD';
export const emptyGrey = '#E5E3E8';

export const StyledLink = styled(Link)`
  color: ${paletteHelper('textInvertedStatic')};
  &:visited {
    color: ${paletteHelper('textInvertedStatic')};
  }
`;

export const UnsetButtonGroup = styled(ButtonGroup).attrs({
  dangerouslySetClassNames: {
    label: 'buttonGroupLabel',
  },
})`
  .buttonGroupLabel {
    color: var(--ck-textPrimary);
  }
`;
