import React, { useState } from 'react';
import styled from 'styled-components';
import { paletteHelper, spacingHelper, Divider } from '@construct-kit/core';

import Line from './Line';
import ExpandButton from './ExpandButton';

const StyledContainer = styled.div`
  border: 1px solid ${paletteHelper('borderSecondary')};
  border-radius: ${spacingHelper('small')};
  margin-top: ${spacingHelper('extraSmall')};
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
`;

export interface TableItem {
  title: string;
  value: string | undefined;
}

interface DetailsTableProps {
  items: TableItem[];
}

const DetailsTable = ({ items }: DetailsTableProps) => {
  const [isExpand, setIsExpand] = useState(false);

  const displayItems =
    items.length <= 4 ? items : isExpand ? items : items.slice(0, 4);

  return (
    <StyledContainer>
      {displayItems.map((item, index) => (
        <div key={item.title}>
          <Line {...item} />
          {index !== displayItems.length - 1 && <StyledDivider />}
        </div>
      ))}
      {items.length >= 4 && (
        <>
          {isExpand && <StyledDivider />}
          <ExpandButton
            isExpand={isExpand}
            onClick={() => setIsExpand(!isExpand)}
          />
        </>
      )}
    </StyledContainer>
  );
};

export default DetailsTable;
