import { gql } from 'urql/core';

export const TooltipsFragment = gql/* GraphQL */ `
  fragment TooltipsFragment on LoanCalculatorTooltips {
    monthlyBudget {
      title
      content
      linkText
      linkUrl
    }
    deposit {
      title
      content
      linkText
      linkUrl
    }
    propertyPrice {
      title
      content
      linkText
      linkUrl
    }
    interestRate {
      title
      content
      linkText
      linkUrl
    }
    lmiEstimate {
      title
      content
      linkText
      linkUrl
    }
    stampDuty {
      title
      content
      linkText
      linkUrl
    }
    transferFee {
      title
      content
      linkText
      linkUrl
    }
    governmentFees {
      title
      content
      linkText
      linkUrl
    }
    estimatedIncome {
      title
      content
      linkText
      linkUrl
    }
    estimatedExpenses {
      title
      content
      linkText
      linkUrl
    }
    repaymentType {
      title
      content
      linkText
      linkUrl
    }
    loanTerm {
      title
      content
      linkText
      linkUrl
    }
  }
`;
