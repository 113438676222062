import React from 'react';

import ErrorBoundary from '../../shared/ErrorBoundary';
import { COMMON_QUICK_LEADS_COMPONENT_NAME } from '../../../constants/componentName';
import { CommonQuickLeadsProps } from './types';

import { CommonQuickLeadsModal } from '.';

const CommonQuickLeadsWithErrorBoundary = (props: CommonQuickLeadsProps) => (
  <ErrorBoundary component={COMMON_QUICK_LEADS_COMPONENT_NAME}>
    <CommonQuickLeadsModal {...props} />
  </ErrorBoundary>
);

export default CommonQuickLeadsWithErrorBoundary;
