import { Inline, Stack, Tooltip, TooltipProps } from '@construct-kit/core';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { trackEvent as trackEventFun } from '@argonaut/analytics';

import { PrimaryColorText, StyledLink } from '../styles';
import { Tooltip as LoanCalculatorTooltip } from '../../@types/__generated__/graphql';
import { StyledButton, StyledSlider, StyledStack } from './styles';
import { getFinxModalOpenEventDataGetter } from '../../tracking/eventData/modalOpen';
import { TrackEventProps } from '../../tracking/types';
import { Z_INDEX_HIGH_PRIORITY } from '../../constants';
import { handleKeyDown } from '../../utils/handleKeyDown';
import {
  LoanTermInput,
  PercentInput,
  StyledCurrencyInput,
} from '../FormattedNumericInput/style';

export type SliderCTA = {
  modalCTAText: string;
  onCTAClick: () => void;
};

export enum SliderQuestionType {
  CURRENCY = 'CURRENCY',
  PERCENT = 'PERCENT',
  LOAN_TERM = 'LOAN_TERM',
}

type Props = {
  title: string;
  value: number;
  sliderValues: {
    minValue: number;
    maxValue: number;
    stepIncrement: number;
  };
  fetching: boolean;
  subtitle?: string;
  subtitleTooltip?: LoanCalculatorTooltip;
  sliderCTA?: SliderCTA;
  titleTooltip?: string;
  sliderType: SliderQuestionType;
  tooltipProps?: TooltipProps;
  refetchFunction?: (value: number, userAction: string) => void;
  setLoanTerm?: Dispatch<SetStateAction<number>>;
  disabled: boolean;
  trackEventModalType?: string;
  isInitialization?: boolean;
} & TrackEventProps;

const sliderTrackingUserAction = 'selection';
const inputTrackingUserAction = 'field_change';

export const SliderQuestion = ({
  value,
  sliderValues,
  fetching,
  title,
  subtitle,
  subtitleTooltip,
  sliderCTA,
  titleTooltip,
  sliderType = SliderQuestionType.CURRENCY,
  tooltipProps,
  refetchFunction,
  setLoanTerm,
  disabled,
  trackEvent = trackEventFun,
  trackEventModalType,
  isInitialization = false,
}: Props) => {
  const [sliderVal, setSliderVal] = useState(`${value}`);

  const shouldDisableInteraction = fetching || disabled;

  const isInitializingOrHasError = (fetching && isInitialization) || disabled;

  const isRecalculating = fetching && !isInitialization;

  const handleUpdates = (trackingAction: string) => {
    if (sliderType !== SliderQuestionType.LOAN_TERM) {
      refetchFunction && refetchFunction(Number(sliderVal), trackingAction);
    } else {
      setLoanTerm && setLoanTerm(Number(sliderVal));
    }
  };

  const handleSliderEnd = () => {
    handleUpdates(sliderTrackingUserAction);
  };

  const handleInputValueChanges = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSliderVal(e.target.value);
    handleUpdates(inputTrackingUserAction);
  };

  const renderSliderInput = (sliderType: SliderQuestionType) => {
    switch (sliderType) {
      case SliderQuestionType.CURRENCY:
        return (
          <StyledCurrencyInput
            noGap
            label={`currency - ${title}`}
            hideLabel
            sizeVariant="small"
            value={sliderVal}
            onBlur={handleInputValueChanges}
            onChange={(e) => setSliderVal(e.target.value)}
            max={99999999}
            disabled={isInitializingOrHasError}
            onKeyDown={handleKeyDown}
          />
        );
      case SliderQuestionType.PERCENT:
        return (
          <PercentInput
            label={`percent - ${title}`}
            hideLabel
            sizeVariant="small"
            value={sliderVal}
            onBlur={handleInputValueChanges}
            onChange={(e) => {
              setSliderVal(e.target.value);
            }}
            max={sliderValues.maxValue}
            min={sliderValues.minValue}
            disabled={isInitializingOrHasError}
            onKeyDown={handleKeyDown}
            suffix="%"
            fractionDigits={2}
          />
        );
      case SliderQuestionType.LOAN_TERM:
        return (
          <LoanTermInput
            label={`loan term = ${title}`}
            hideLabel
            inputMode="numeric"
            sizeVariant="small"
            value={sliderVal}
            onBlur={handleInputValueChanges}
            onChange={(e) => {
              setSliderVal(e.target.value);
            }}
            max={sliderValues.maxValue}
            min={sliderValues.minValue}
            disabled={isInitializingOrHasError}
            onKeyDown={handleKeyDown}
            suffix=" years"
          />
        );
    }
  };

  useEffect(() => {
    if (!fetching) {
      setSliderVal(`${value}`);
    }
  }, [value, fetching]);

  return (
    <StyledStack gap="twoExtraSmall" isRecalculating={isRecalculating}>
      <Inline justifyContent="space-between" grow={false} gap="0">
        <Stack gap="extraSmall">
          <Inline grow={false} gap="twoExtraSmall">
            <PrimaryColorText variant="subtitle01">{title}</PrimaryColorText>
            {titleTooltip && (
              <Tooltip
                sizeVariant="small"
                disabled={shouldDisableInteraction}
                zIndex={Z_INDEX_HIGH_PRIORITY}
                {...tooltipProps}>
                {titleTooltip}
              </Tooltip>
            )}
          </Inline>
          {subtitle && (
            <Inline grow={false} gap="twoExtraSmall">
              <PrimaryColorText variant="body02">{subtitle}</PrimaryColorText>
              {subtitleTooltip && (
                <Tooltip
                  sizeVariant="small"
                  disabled={shouldDisableInteraction}
                  zIndex={Z_INDEX_HIGH_PRIORITY}>
                  {subtitleTooltip.content}{' '}
                  {subtitleTooltip.linkText && subtitleTooltip.linkUrl && (
                    <StyledLink
                      variant="secondary"
                      target="_blank"
                      href={subtitleTooltip.linkUrl}>
                      {subtitleTooltip.linkText}
                    </StyledLink>
                  )}
                </Tooltip>
              )}
            </Inline>
          )}
        </Stack>
        {renderSliderInput(sliderType)}
      </Inline>
      <StyledSlider
        value={sliderVal}
        min={sliderValues.minValue}
        max={sliderValues.maxValue}
        step={sliderValues.stepIncrement}
        label={'slider - ' + title}
        hideLabel
        onChange={(e) => {
          setSliderVal(e.target.value);
        }}
        onMouseUp={handleSliderEnd}
        onTouchEnd={handleSliderEnd}
        disabled={isInitializingOrHasError}
      />
      {sliderCTA && (
        <StyledButton
          variant="link-secondary"
          onClick={() => {
            sliderCTA.onCTAClick();
            trackEventModalType &&
              trackEvent(getFinxModalOpenEventDataGetter(trackEventModalType));
          }}
          disabled={shouldDisableInteraction}>
          <PrimaryColorText disabled={shouldDisableInteraction}>
            {sliderCTA.modalCTAText}
          </PrimaryColorText>
        </StyledButton>
      )}
    </StyledStack>
  );
};
