import { gql } from 'urql/core';

import { CalculatorResultsFragment } from '../fragments/calculatorResultsFragment';
import { TooltipsFragment } from '../fragments/tooltipsFragment';

export const getLoanCalculator = gql/* GraphQL */ `
  ${CalculatorResultsFragment}
  ${TooltipsFragment}
  query FinXLoanCalculatorFullDataQuery(
    $mockedStatus: String
    $listingDetails: ListingDetailsInput!
    $financialDetails: FinancialDetailsInput
  ) {
    loanCalculator {
      calculatorResults(
        mockedStatus: $mockedStatus
        listingDetails: $listingDetails
        financialDetails: $financialDetails
      ) {
        ...CalculatorResultsFragment
      }
      estimatedPropertyPriceDescription(listingDetails: $listingDetails)
      tooltips(listingDetails: $listingDetails) {
        ...TooltipsFragment
      }
    }
  }
`;
