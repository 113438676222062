import { DataGetter } from '@argonaut/analytics';

import { EventData } from '../types';

const EVENT_NAME_CONTACT_FORM_MODAL_OPEN = 'contact_form_modal_open';
const SCHEMA_CONTACT_FORM_MODAL_OPEN =
  'iglu:au.com.realestate/contact_form_modal_open/jsonschema/1-0-14';

const EVENT_NAME_FINX_MODAL_OPEN = 'finx_modal_open';
const SCHEMA_FINX_MODAL_OPEN =
  'iglu:au.com.realestate/finx_modal_open/jsonschema/1-0-0';

export interface ModalOpenEventData extends EventData {
  schema: string;
  data: {
    event_name: string;
    form_name?: string;
    modal_type?: string;
  };
}

export const getContactFormModalOpenEventDataGetter =
  (formName: string): DataGetter =>
  (): ModalOpenEventData => ({
    schema: SCHEMA_CONTACT_FORM_MODAL_OPEN,
    data: {
      event_name: EVENT_NAME_CONTACT_FORM_MODAL_OPEN,
      form_name: formName,
    },
  });

export const getFinxModalOpenEventDataGetter =
  (modalType: string): DataGetter =>
  (): ModalOpenEventData => ({
    schema: SCHEMA_FINX_MODAL_OPEN,
    data: {
      event_name: EVENT_NAME_FINX_MODAL_OPEN,
      modal_type: modalType,
    },
  });
