import {
  mediaQueryHelper,
  Stack,
  Dialog,
  spacingHelper,
  Text,
  paletteHelper,
  Divider,
  Inline,
} from '@construct-kit/core';
import styled from 'styled-components';

export const ReactiveArea = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${spacingHelper('large')};
  ${mediaQueryHelper('tablet')} {
    flex-direction: row;
  }
  margin-bottom: ${spacingHelper('large')};
`;

const FlexBasisSection = styled(Stack)`
  flex-grow: 1;
`;

export const SlidersSection = styled(FlexBasisSection)``;

export const YourDetailsSection = styled(FlexBasisSection)``;

export const DialogContent = styled(Stack).attrs({
  gap: 'extraSmall',
})`
  padding: ${spacingHelper('medium')} ${spacingHelper('small')};
`;

export const StyledDialog = styled(Dialog)`
  min-width: 360px;
`;

export const StyledDivider = styled(Divider)`
  margin: 0;
`;

export const PrimaryText = styled(Text)`
  color: ${paletteHelper('textPrimary')};
`;

export const StyledInline = styled(Inline).attrs({ grow: false })`
  display: flex;
  justify-content: space-between;
`;

export const Content = styled(Stack)`
  padding: 0 ${spacingHelper('medium')};
  ${mediaQueryHelper('tablet')} {
    padding: 0;
  }
`;
