import { useState } from 'react';
import {
  Button,
  H4,
  Skeleton,
  Small,
  SuccessFilledMd,
} from '@construct-kit/core';
import { trackEvent as trackEventFun } from '@argonaut/analytics';

import { errorState } from '../../graphql/errorState';
import { useLoanCalculatorData } from '../../hooks/useLoanCalculatorContext';
import { AffordabilityStatus } from '../../@types/__generated__/graphql';
import { StyledCard, CtaInfo, StyledInline } from './styles';
import { formNameForNextStep, NextStepModal } from '../NextStepModal';
import { isUserSignedIn } from '../../utils/userHelper';
import { transformLoanCalculatorToTableItems } from '../../utils/transformLoanCalculatorToVariables';
import { AlertType } from '../AlertBox';
import {
  getContactFormModalOpenEventDataGetter,
  getFinxModalOpenEventDataGetter,
} from '../../tracking/eventData/modalOpen';
import { TrackEventProps } from '../../tracking/types';
import { formNameForYourDetailsModal } from '../YourDetails';

type CTAInfo = {
  title: string;
  successStatus: boolean;
  description: string;
  ctaText: string;
};

export const ctas: Record<AffordabilityStatus, CTAInfo> = {
  [AffordabilityStatus.Default]: {
    title: 'Find out if you can afford this property',
    successStatus: false,
    description:
      'Enter your income and expenses to figure out your monthly budget/the affordability breakdown for this property.',
    ctaText: 'Enter your details',
  },
  [AffordabilityStatus.WithinBudget]: {
    title: 'You’re ready to make this real!',
    successStatus: true,
    description:
      'Get connected with an experienced broker to make your next move.',
    ctaText: 'Discuss your options',
  },
  [AffordabilityStatus.ExceedBudget]: {
    title: 'Repayments and expenses exceeding income',
    successStatus: false,
    description:
      "It looks like your repayments and expenses will stretch your budget. But don't worry, a mortgage broker may be able to help.",
    ctaText: 'Discuss your options',
  },
};

export type NextStepCtaProps = {
  setIsYourDetailsModalOpen: (isOpen: boolean) => void;
};

export const NextStepCta = ({
  trackEvent = trackEventFun,
  setIsYourDetailsModalOpen,
}: TrackEventProps & NextStepCtaProps) => {
  const {
    loanCalculator,
    fetching,
    error,
    propertyDetails,
    alertType,
    isInitialization,
  } = useLoanCalculatorData();

  const [isContactFormModalOpen, setIsContactFormModalOpen] = useState(false);

  const nextSteps =
    error || !loanCalculator
      ? errorState?.calculatorResults.calculatorView.nextSteps
      : loanCalculator?.calculatorResults.calculatorView.nextSteps;

  const cta = ctas[nextSteps.affordabilityStatus];

  const handleCtaOpen = () => {
    if (nextSteps.affordabilityStatus === AffordabilityStatus.Default) {
      setIsYourDetailsModalOpen(true);
      trackEvent(getFinxModalOpenEventDataGetter(formNameForYourDetailsModal));
    } else {
      setIsContactFormModalOpen(true);
      trackEvent(getContactFormModalOpenEventDataGetter(formNameForNextStep));
    }
  };

  return (
    <StyledCard>
      <CtaInfo>
        <Skeleton loading={fetching && isInitialization} height="100%">
          {cta.successStatus ? (
            <StyledInline>
              <SuccessFilledMd />
              <H4>{cta.title}</H4>
            </StyledInline>
          ) : (
            <H4>{cta.title}</H4>
          )}
          <Small>{cta.description}</Small>
        </Skeleton>
      </CtaInfo>
      <Button
        variant="secondary"
        disabled={
          fetching ||
          alertType === AlertType.InitialisationError ||
          alertType === AlertType.HigherDepositWarning
        }
        onClick={() => handleCtaOpen()}>
        {cta.ctaText}
      </Button>
      <NextStepModal
        isOpen={isContactFormModalOpen}
        closeModal={() => setIsContactFormModalOpen(false)}
        detailsWithLabels={
          isUserSignedIn() && loanCalculator
            ? transformLoanCalculatorToTableItems(loanCalculator)
            : []
        }
        address={propertyDetails?.address}></NextStepModal>
    </StyledCard>
  );
};
