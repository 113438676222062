import { Inline } from '@construct-kit/core';

import { SquareBar, Square } from './styles';
import { AffordabilityStatus } from '../../@types/__generated__/graphql';

export const StatusIndicator = ({
  affordabilityStatus,
}: {
  affordabilityStatus: AffordabilityStatus;
}) => (
  <Inline>
    <SquareBar $affordabilityStatus={affordabilityStatus}>
      <Square data-testid="square-1" />
      <Square data-testid="square-2" />
      <Square data-testid="square-3" />
    </SquareBar>
  </Inline>
);
