import {
  createRef,
  useCallback,
  useEffect,
  useState,
  ChangeEvent,
  FocusEvent,
} from 'react';

import { StyledUnsetInputInput } from './style';
import { FormattedNumericInputProps } from './type';
import forwardRef from './forwardRef';
import { parseNumericString } from '../../utils/parseNumericString';

export const FormattedNumericInput = forwardRef<
  FormattedNumericInputProps,
  'input'
>(
  ({
    min,
    max,
    onBlur,
    onFocus,
    onChange,
    value,
    suffix,
    fractionDigits,
    ...remainingProps
  }) => {
    const internalRef = createRef<HTMLInputElement>();

    const [inputValue, setInputValue] = useState(
      value !== undefined
        ? parseNumericString(value.toString(), max, min, suffix, fractionDigits)
        : ''
    );

    const setFormattedInputValue = useCallback(
      (val: string) => {
        const newValue = parseNumericString(
          val,
          max,
          min,
          suffix,
          fractionDigits
        );
        setInputValue(newValue);
      },
      [fractionDigits, max, min, suffix]
    );

    useEffect(() => {
      if (value !== undefined && internalRef.current) {
        // If user is programatically changing the value, format it
        // If a user is editing the controlled input, do not format it
        document.activeElement === internalRef.current
          ? setInputValue(
              parseNumericString(
                value.toString(),
                max,
                min,
                undefined,
                fractionDigits
              )
            )
          : setFormattedInputValue(value.toString());
      }
    }, [value, internalRef, setFormattedInputValue, max, min, fractionDigits]);

    const eventHandler = <E extends React.ReactEventHandler<HTMLInputElement>>(
      e: Parameters<E>[0],
      setter: (val: string) => void,
      userEvent?: E
    ) => {
      const target = e.target as HTMLInputElement;
      const val = parseNumericString(
        target.value,
        max,
        min,
        undefined,
        fractionDigits
      );
      const usersEvent = { ...e, target: { ...e.target, value: val } };

      setter(val);
      userEvent?.(usersEvent);
    };

    return (
      <StyledUnsetInputInput
        ref={internalRef}
        onBlur={(e: FocusEvent<HTMLInputElement>) =>
          eventHandler(e, setFormattedInputValue, onBlur)
        }
        onFocus={(e: FocusEvent<HTMLInputElement>) =>
          eventHandler(e, setInputValue, onFocus)
        }
        onChange={(e: ChangeEvent<HTMLInputElement>) =>
          eventHandler(e, setInputValue, onChange)
        }
        value={inputValue || ''}
        {...remainingProps}></StyledUnsetInputInput>
    );
  }
);

FormattedNumericInput.displayName = 'FormattedNumericInput';

export default FormattedNumericInput;
