import { Modal, H2, Tooltip, Button, Strong, Text } from '@construct-kit/core';
import { useEffect, useState } from 'react';
import { trackEvent as trackEventFn } from '@argonaut/analytics';

import {
  StyledDialog,
  DialogContent,
  PrimaryText,
  StyledInline,
  StyledDivider,
} from '../Calculator/styles';
import { SliderQuestion, SliderQuestionType } from '../SliderQuestion';
import { errorState } from '../../graphql/errorState';
import { useLoanCalculatorData } from '../../hooks/useLoanCalculatorContext';
import { useLoanCalculatorEventHandlers } from '../../hooks/useLoanCalculatorEventHandlers';
import { AlertType } from '../AlertBox';
import { LoanCalculator } from '../../@types/__generated__/graphql';
import { TrackEventProps } from '../../tracking/types';
import { getCalculatorInteractionEventDataGetter } from '../../tracking/eventData/calculatorInteraction';
import { Z_INDEX_HIGH_PRIORITY } from '../../constants';

export const modalTypeForDepositModal = 'available_deposit';

export const DepositSection = ({
  trackEvent = trackEventFn,
}: TrackEventProps) => {
  const {
    loanCalculator,
    fetching,
    error,
    refetch,
    alertType,
    isInitialization,
  } = useLoanCalculatorData();

  const [isDepositModalOpen, setIsDepositModalOpen] = useState(false);

  const { handleDepositChange } = useLoanCalculatorEventHandlers({ refetch });

  const [loanCalculatorData, setLoanCalculatorData] = useState<LoanCalculator>(
    error || !loanCalculator ? errorState : loanCalculator
  );

  useEffect(() => {
    if (loanCalculator && AlertType.RefetchError !== alertType) {
      setLoanCalculatorData(loanCalculator);
    }
  }, [alertType, loanCalculator]);

  const handleValueChange = (value: number, userAction: string) => {
    if (
      value ==
      loanCalculatorData.calculatorResults.calculatorView.deposit.amount.value
    ) {
      return;
    }
    setLoanCalculatorData((prev) => ({
      ...prev,
      calculatorResults: {
        ...prev.calculatorResults,
        calculatorView: {
          ...prev.calculatorResults.calculatorView,
          deposit: {
            ...prev.calculatorResults.calculatorView.deposit,
            amount: {
              ...prev.calculatorResults.calculatorView.deposit.amount,
              value,
            },
          },
        },
      },
    }));
    handleDepositChange(value);

    const fieldUpdated: string = 'home-loan-calculator:deposit';
    trackEvent(
      getCalculatorInteractionEventDataGetter(userAction, fieldUpdated)
    );
  };

  const deposit = loanCalculatorData.calculatorResults.calculatorView.deposit;

  const tooltips = loanCalculatorData.tooltips;

  return (
    <>
      <SliderQuestion
        fetching={fetching}
        title="Deposit"
        value={deposit.amount.value}
        sliderValues={deposit.amount.slider}
        titleTooltip={tooltips.deposit.content}
        sliderCTA={{
          modalCTAText: deposit.description,
          onCTAClick: () => setIsDepositModalOpen(true),
        }}
        sliderType={SliderQuestionType.CURRENCY}
        refetchFunction={handleValueChange}
        disabled={alertType === AlertType.InitialisationError}
        trackEventModalType={modalTypeForDepositModal}
        isInitialization={isInitialization}
      />
      <Modal
        opened={isDepositModalOpen}
        onRequestClose={() => setIsDepositModalOpen(false)}
        getAppElement={() => 'body'}>
        <StyledDialog
          header={<H2>Available deposit</H2>}
          onClose={() => setIsDepositModalOpen(false)}
          autoFullScreen={true}>
          <DialogContent>
            <StyledInline>
              <Text>Deposit</Text>
              <PrimaryText>{deposit.amount.formatted}</PrimaryText>
            </StyledInline>
            <StyledInline>
              <Tooltip
                trigger={({ toggle }) => (
                  <Button onClick={() => toggle()} variant="link-secondary">
                    {tooltips.stampDuty.title}
                  </Button>
                )}
                zIndex={Z_INDEX_HIGH_PRIORITY}>
                {tooltips.stampDuty.content}
              </Tooltip>
              <PrimaryText>{deposit.upfrontCosts.stampDuty}</PrimaryText>
            </StyledInline>
            <StyledInline>
              <Tooltip
                trigger={({ toggle }) => (
                  <Button onClick={() => toggle()} variant="link-secondary">
                    {tooltips.transferFee.title}
                  </Button>
                )}
                zIndex={Z_INDEX_HIGH_PRIORITY}>
                {tooltips.transferFee.content}
              </Tooltip>
              <PrimaryText>{deposit.upfrontCosts.transferFee}</PrimaryText>
            </StyledInline>
            <StyledInline>
              <Tooltip
                trigger={({ toggle }) => (
                  <Button onClick={() => toggle()} variant="link-secondary">
                    {tooltips.governmentFees.title}
                  </Button>
                )}
                zIndex={Z_INDEX_HIGH_PRIORITY}>
                {tooltips.governmentFees.content}
              </Tooltip>
              <PrimaryText>{deposit.upfrontCosts.governmentFees}</PrimaryText>
            </StyledInline>
            <StyledDivider />
            <StyledInline>
              <PrimaryText>Available deposit</PrimaryText>
              <PrimaryText>
                <Strong>{deposit.availableDeposit.availableAmount}</Strong>{' '}
                {deposit.availableDeposit.formattedRatio}
              </PrimaryText>
            </StyledInline>
          </DialogContent>
        </StyledDialog>
      </Modal>
    </>
  );
};
